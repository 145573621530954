import { Box, Breadcrumbs, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Paper, StyledEngineProvider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import useAuth from '../../hooks/UseAuth';
import ReactSignatureCanvas from 'react-signature-canvas';
import CustomDatatable from '../Utils/CustomDatatable';
import ProcessDialog from '../Utils/ProcessDialog';
import { Close, CloudUpload } from '@mui/icons-material';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useCommentsContext from '../../hooks/UseCommentsContext';
import CancelledPullRequest from './CancelledPullRequest';
import UnproductivePullout from './UnproductivePullout';
import UpdateRemarks from './UpdateRemarks';
import MoreOptionBtn from './MoreOptionBtn';
import EditRequest from './EditRequest';
import BlankTapor from '../Utils/BlankTapor';
import TapsPrint from '../Utils/TapsPrint';

const CustomPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 20
}));

const Item = ({ children }) => (
  <CustomPaper elevation={0}>
    {children}
  </CustomPaper>
);



function PulloutRequestDetails() {
    const theme = useTheme();
    const {auth} = useAuth();
    const { requestCode } = useParams();
    const axios = AxiosCustomConfig();
    const {setDoneUpdate} = useCommentsContext();
    const [openMessageDialog,setOpenMessageDialog] = useState(false)
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [requestDetails, setRequestDetails] = useState({});
    const [requests, setRequests] = useState({
      request_code: requestCode,
      asset_images: '',
      pullout_date: '',
      asset_code: '',
      account_code: '',
      branch_code: '',
      tadr_copy: '',
    });
    const [showProgress, setShowProgress] = useState(false);
    const [openDeployAssetDialog, setOpenDeployAssetDialog] = useState(false);
    const [doneApproving, setDoneApproving] = useState(false);
    const [onEditOrDelete, setOnEditOrDelete] = useState(false);

    useEffect(() => {
        let ignore = false;

        const getRequestDetails  = async (ignore) => {
            try {
                setLoading(true);
                const options = {
                    'method': 'POST',
                    'url' : '/PulloutRequests/getAllPulloutRequestsDetails.json',
                    'data': {'request_code': requestCode}
                  }
                  const response = await axios(options);
                  const data = response.data;
                  if (!ignore) {
                    setRequestDetails(data);
                    setRequests((prev)=> ({...prev, 
                      asset_code : data.asset_code,
                      account_code : data.account_code,
                      branch_code : data.branch_code,
                    }));
                  }
            } catch (error) {
                setOpenMessageDialog(true);
                setMessage(` ${error.toJSON().message}. Network error.`);
            } finally {
                setLoading(false);
            }
        }


        getRequestDetails(ignore)
        return () => {
            ignore = true
        };
    }, [requestCode, doneApproving, onEditOrDelete]);

    const renderDetailItem = (title, value) => (
      <Grid container item xs={12} md={12} lg={6}>
        <Typography variant="body1" marginRight={2}>
          <strong>{title} </strong>
        </Typography>
        <Typography variant="body1">
        {title === 'Status:' ? (
          value === "Processing" ? (
            <Chip label={value} sx={{ backgroundColor: '#D7D6D4'}} />
          ) : value === "Pulled out" ? (
            <Chip label={value} sx={{ backgroundColor: '#3AD658' }} />
          ) : value === "Cancelled" ? (
            <Chip label={value} sx={{ backgroundColor: '#CA863E', color: '#fff' }} />
          ) : value === "Unproductive" ? (
            <Chip label={value} sx={{backgroundColor: '#E8484F', color: '#fff'}} />
          ) : value === "Unproductive & cancelled" ? (
            <Chip label={value} sx={{ backgroundColor: '#843B10', color: '#fff' }} />
          ) : value === "Hold" ? (
            <Chip label={value} sx={{ backgroundColor: '#F8FD6A' }} />
          ) : value === "Missing asset - unpro" ? (
            <Chip label={value} sx={{ backgroundColor: '#DD1F00', color: '#fff'}} />
          ) : (
            <Chip label={value} color='primary' />
          )
        ) : (
          value
        )}
        </Typography>
      </Grid>
    );

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      return `${year}-${month}-${day}`;
    }

    const resetForm = () => {
      setRequests({
        request_code: requestCode,
        asset_images: [],
        pullout_date: '',
        asset_code: '',
        account_code: '',
        branch_code: '',
        tadr_copy: '',
      })
    }

  const handleImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 5) {
      setOpenMessageDialog(true);
      setMessage('You can only upload a maximum of 5 images');
      return;
    }

    setRequests((prev) => ({ ...prev, asset_images: [...prev.asset_images, ...selectedFiles]}))
  };

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = requests.asset_images.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setRequests(prevState => ({
      ...prevState,
      asset_images: updatedImages
    }));
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleSaveProofOfDelivery = async () => {

    if (!requests.pullout_date) {
      setOpenMessageDialog(true);
      setMessage('Please select pull out date.')
      return
    }

    if (requests.asset_images.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Please upload asset image.')
      return
    }
    if (!requests.tadr_copy) {
      setOpenMessageDialog(true);
      setMessage('Please upload tadr copy.')
      return
    }

    try{
      setLoading(true);
      setShowProgress(true);

      const formData = new FormData();
      if (requests.asset_images.length >  0) {
        requests.asset_images.forEach((file, index) => {
          formData.append(`asset_images_${index}`, file);
        });
      }

      formData.append('request_code', requests.request_code);
      formData.append('pullout_date', requests.pullout_date);
      formData.append('asset_code', requests.asset_code);
      formData.append('account_code', requests.account_code);
      formData.append('branch_code', requests.branch_code);
      formData.append('tadr_copy', requests.tadr_copy);

      const options = {
          'method': 'POST',
          'url' : '/PulloutRequests/saveProofOfPullOut.json',
          'data': formData
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setOpenDeployAssetDialog(false)
          setDoneUpdate(prev => !prev)
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
          setDoneApproving(true);
          resetForm();
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. There's somethe wrong with the server.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }
  }

  const handleCloseDeployAssetDialog = () => {
    setOpenDeployAssetDialog(false)
  }

  const handleAssetImageChange = (event) => {
    const file = event.target.files[0];
    setRequests((prev) => ({...prev, asset_image: file}));
  };
  const handleTADRChange = (event) => {
    const file = event.target.files[0];
    setRequests((prev) => ({...prev, tadr_copy: file}));
  };

  return (
    <>

    <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/pulloutRequest"
          >
            Request
          </Link>
          <Typography color="text.primary">Details</Typography>
        </Breadcrumbs>
      </Box>
    {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress /> {/* Render CircularProgress while loading */}
      </Box>
      )
    :(
      <>
      <Box>
        <Grid container style={{ height: '100%' }}  spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Paper elevation={1} style={{ height: '100%', padding: '8px', display:'flex', gap: '5px' }}>
              {requestDetails && <EditRequest requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete} />}
              {Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 6 || Number(auth.role) === 0 || Number(auth.role) === 3  ? (
                <>

                  <TapsPrint requestDetails={requestDetails} />
                  <BlankTapor requestDetails={requestDetails} />
                  <UpdateRemarks requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete} />
                  <MoreOptionBtn requestDetails={requestDetails} setOnEditOrDelete={setOnEditOrDelete} />

                </>
                ) : ''}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Item>
              {renderDetailItem('JDE/Syspro no.:', requestDetails.account_code)}
              {renderDetailItem('Customer:', requestDetails.account_name)}
              {renderDetailItem('Distributor/Chain:', requestDetails.branch_name)}
              {renderDetailItem('Business Address:', requestDetails.business_address)}
              {renderDetailItem('Asset name:', requestDetails.asset_name)}
              {renderDetailItem('Asset type:', requestDetails.asset_type_name)}
              {renderDetailItem('Pullout type:', requestDetails.pullout_type_name)}
              {renderDetailItem('Remarks:', requestDetails.remarks)}
              {renderDetailItem('Status remarks:', requestDetails.other_remarks)}
              {renderDetailItem('Status:', requestDetails.status_name)}
            </Item>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Item>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="body1">
                    <strong>Expected Pullout date: </strong> {requestDetails.expected_pullout_date && formatDate(requestDetails.expected_pullout_date)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <strong>Requestor name: </strong> {requestDetails.requestor_name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <strong>Requested Date: </strong> {requestDetails.created && formatDate(requestDetails.created)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <strong>Pull out Date: </strong> {requestDetails.pullout_date && formatDate(requestDetails.pullout_date)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="column">
                {  (Number(auth.role) === 6 || Number(auth.role) === 0) && (
                  <>
                    <Button 
                      disabled={(requestDetails.status_code !== 0) ? true : false}
                      variant="contained" 
                      size="small" 
                      sx={{
                            color: theme.palette.primary.main,
                            bgcolor: '#7acda1',
                            '&:hover': {
                              bgcolor: '#ACE1C3',
                            },
                            marginBottom: '10px'
                          }}
                      onClick={() => setOpenDeployAssetDialog(true)}
                    >Pullout Asset</Button> 
                    <CancelledPullRequest requestDetails={requestDetails} setDoneApproving={setDoneApproving}/>
                    <UnproductivePullout requestDetails={requestDetails} setDoneApproving={setDoneApproving}/>
                  </>
                )}
              </Grid>
            </Item>
            
          </Grid>
          <Grid item xs={4}>
          <Item>
            <Typography>Documents</Typography>
              <Grid item>
                <Typography variant="subtitle2">TAPOR file: </Typography>
                <a href={requestDetails.tapor_file_url} download>
                {requestDetails.tapor_file}
                </a>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Asset Photo:</Typography>
                {requestDetails?.asset_images_url?.length > 0 ? (
                  requestDetails.asset_images_url.map((image, index) => (
                    <div key={index}>
                      <a href={image.url} download>
                        {image.name}
                      </a>
                    </div>
                  ))
                ) : (
                  <Typography variant="body2">No asset photos available.</Typography>
                )}
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">TADR copy: </Typography>
                <a href={requestDetails.tadr_copy_url} download>
                {requestDetails.tadr_copy}
                </a>
              </Grid>
          </Item>
          </Grid>
          <Grid item xs={8} >
            <Item>
            <Typography>Upload image</Typography>
            <Grid item>
              <Paper
                sx={{
                    width: 200
                }}
                >
              </Paper>
            </Grid>
              </Item>
          </Grid>
        </Grid>
    </Box>
    </>
    )}
    
    <Dialog fullWidth={true} maxWidth={'md'} open={openDeployAssetDialog} onClose={handleCloseDeployAssetDialog}>
        <DialogTitle>Pulled out Asset</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="initial">Pulled out Date:</Typography>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Pulled out Date"
                            value={requests.pullout_date}
                            onChange={(newValue) => setRequests({...requests, pullout_date: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss')})}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="initial">Asset photo: </Typography>
              <ul>
              {requests?.asset_images?.length > 0 && requests.asset_images.map((file, index) => (
                <li key={index}>
                  {file.name}
                  <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                    <Close />
                  </IconButton>
                </li>
              ))}
            </ul>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                  <Button
                    variant="contained" 
                    size="small" 
                    color='info'
                    startIcon={<CloudUpload />}
                    component="label"
                  >Upload Asset Photo
                    <VisuallyHiddenInput type="file" accept="image/*" multiple onChange={handleImageChange}/>
                  </Button> 
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="initial">TADR Copy:   {requests.tadr_copy && requests.tadr_copy.name}</Typography>
              <FormControl fullWidth>
                <Button
                  component="label"
                  variant="contained" 
                  size="small" 
                  color='primary'
                  startIcon={<CloudUpload />}
                >Upload TADR Copy
                  <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleTADRChange}/>
                </Button> 
              </FormControl>
            </Grid>
          </Grid>
          
        </DialogContent>

        <DialogActions>
          <FormControl fullWidth>
            <Button variant="contained" size="small" color='success'  onClick={handleSaveProofOfDelivery}>Save & pullout</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button onClick={() => setOpenDeployAssetDialog(false)} color="error">Close</Button>
          </FormControl>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            if (doneApproving) {
              setDoneApproving(false)
            }
            }}
          message={message}
        />
    </StyledEngineProvider>
    </>
  )
}

export default PulloutRequestDetails