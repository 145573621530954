import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, StyledEngineProvider, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function EditAccounts({accountsData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    old_account_code: '',
    account_code: '',
    account_name: '',
    description: '',
    customer_type_array: [],
    customer_chain_array: [],
    customer_type: '',
    customer_chain: '',
    csid: '',
    business_address: '',
    residential_address: '',
    owner_mngr_buyer_name: '',
    distibutor_sales_representative: '',
    contact: '',
    region: '',
    region_array: [],
    channel: '',
    channel_array: [],
    terms: '',
    terms_array: [],
    discount: '',
    discount_array: [],
    days_cover: '',
    days_cover_array: [],
    frequency: '',
    frequency_array: [],
    active: 0,
    store_image: '',
    business_permit_image: '',
    customer_id_image: '',
    proof_of_billing: '',
    id : '',
    distributor_company_id : '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const [customerTypes, setcustomerTypes] = useState([]);
  const [loadingCustomerTypes, setLoadingustomerTypes] = useState(false);
  const [openCustomerTypes, setOpenustomerTypes] = useState(false);
  const [customerChains, setcustomerChains] = useState([]);
  const [loadingCustomerChains, setLoadingustomerChains] = useState(false);
  const [openCustomerChains, setOpenustomerChains] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const generateCSID = () => {
    const min = 10000000; // 8 digits minimum
    const max = 99999999; // 8 digits maximum
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    
    const { customer_no, store_name, id, csid, branch_code, residential_address, business_address, contact, distributor_account, customer_type, owner_mngr_buyer_name, region, channel, terms, discount, days_cover, frequency, active, distibutor_sales_representative, distributor_company_id} = accountsData;
    
    const isActive = active === 1 ? 'Yes' : 'No';
    const updateCsid = csid !== '' ? csid : generateCSID()
    console.log(isActive)
    console.log(active)
    setEditData((prevData) => ({ 
      ...prevData, 
      account_code: customer_no || prevData.customer_no, 
      old_account_code: customer_no || prevData.customer_no, 
      account_name: store_name || prevData.store_name, 
      branch_code: branch_code || prevData.branch_code, 
      id: id || prevData.id,
      customer_type_array: {'label' : customer_type, 'value': customer_type},
      customer_chain_array: {'label' : distributor_account, 'value': distributor_account},
      customer_type: customer_type,
      customer_chain: distributor_account,
      csid: updateCsid,
      business_address: business_address,
      residential_address: residential_address,
      owner_mngr_buyer_name: owner_mngr_buyer_name,
      contact: contact,
      region: region,
      region_array: {'label' : region, 'value': region},
      channel: channel,
      channel_array: {'label' : channel, 'value': channel},
      terms: terms,
      terms_array: {'label' : terms, 'value': terms},
      discount: discount,
      discount_array: {'label' : discount, 'value': discount},
      days_cover: days_cover,
      days_cover_array: {'label' : days_cover, 'value': days_cover},
      frequency: frequency,
      frequency_array: {'label' : frequency, 'value': frequency},
      active: isActive,
      store_image: '',
      business_permit_image: '',
      customer_id_image: '',
      proof_of_billing: '',
      distibutor_sales_representative: distibutor_sales_representative,
      distributor_company_id: distributor_company_id,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      old_account_code: '',
      account_code: '',
      account_name: '',
      description: '',
      customer_type_array: [],
      customer_chain_array: [],
      customer_type: '',
      customer_chain: '',
      csid: '',
      business_address: '',
      residential_address: '',
      owner_mngr_buyer_name: '',
      distibutor_sales_representative: '',
      contact: '',
      region: '',
      region_array: [],
      channel: '',
      channel_array: [],
      terms: '',
      terms_array: [],
      discount: '',
      discount_array: [],
      days_cover: '',
      days_cover_array: [],
      frequency: '',
      frequency_array: [],
      active: 0,
      store_image: '',
      business_permit_image: '',
      customer_id_image: '',
      proof_of_billing: '',
      id : '',
      distributor_company_id : '',
    });
  };

  const region = [
    {'label':'GMA', 'value':'GMA'},
    {'label':'NLA', 'value':'NLA'},
    {'label':'SLA', 'value':'SLA'},
    {'label':'VIS', 'value':'VIS'},
    {'label':'MIN', 'value':'MIN'},
  ];
  
  const channel = [
    {'label': 'Bakery', 'value': 'Bakery'},
    {'label': 'CVS/Petrol', 'value': 'CVS/Petrol'},
    {'label': 'Drugstore', 'value': 'Drugstore'},
    {'label': 'Grocery', 'value': 'Grocery'},
    {'label': 'OOH', 'value': 'OOH'},
    {'label': 'Others - OOH', 'value': 'Others - OOH'},
    {'label': 'Others - Retails', 'value': 'Others - Retails'},
    {'label': 'Sari-Sari Store', 'value': 'Sari-Sari Store'},
    {'label': 'School', 'value': 'School'},
    {'label': 'Street Selling', 'value': 'Street Selling'},
    {'label': 'Sub-Distributor', 'value': 'Sub-Distributor'},
    {'label': 'Supermarket', 'value': 'Supermarket'},
    {'label': 'Foodservice', 'value': 'Foodservice'},
    {'label': 'Others', 'value': 'Others'}
  ];

  const terms = [
    {'label':'Cash on Delivery (COD)', 'value':'Cash on Delivery (COD)'},
    {'label':'7 Days', 'value':'7 Days'},
    {'label':'15 Days', 'value':'15 Days'},
    {'label':'30 Days', 'value':'30 Days'},
  ];

  const discount = [
    {'label':'0%', 'value':'0%'},
    {'label':'5%', 'value':'5%'},
    {'label':'8%', 'value':'8%'},
    {'label':'10%', 'value':'10%'},
  ];

  const daysCover = [
    {'label':'Mon', 'value':'Mon'},
    {'label':'Tue', 'value':'Tue'},
    {'label':'Wed', 'value':'Wed'},
    {'label':'Thu', 'value':'Thu'},
    {'label':'Fri', 'value':'Fri'},
    {'label':'Sat', 'value':'Sat'},
  ];
  const frequency = [
    {'label':'F1', 'value':'F1'},
    {'label':'F2', 'value':'F2'},
    {'label':'F4', 'value':'F4'},
    {'label':'F8', 'value':'F8'},
  ];

  const validateRequiredFields = () => {
    const requiredFields = ['customer_type', 'account_code', 'customer_chain', 'business_address', 'residential_address', 'account_name', 'owner_mngr_buyer_name', 'contact'];

    for (const field of requiredFields) {
      if (!editData[field] || (Array.isArray(editData[field]) && editData[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }

    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    if (!isValidSpecialCharacter(editData.account_name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Account Name.')
        return
    }

    if (!isValidSpecialCharacter(editData.description) && editData.description !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/ApiAccounts/updateAccounts.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      console.log(error);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCustomerTypeChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, customer_type_array: selectValue, customer_type: selectValue.customer_type}))
    }
  }
  const handleCustomerCHainChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, customer_chain_array: selectValue, customer_chain: selectValue.customer_chain}))
    }
  }

  const getCustomerTypes = async (ignore) => {
    setLoadingustomerTypes(true)
    try {
        const options = {
            method: 'POST',
            url: '/CustomerTypes/getCustomerTypesForAutocomplete.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setcustomerTypes(response.data);
        }
    setLoadingustomerTypes(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;
  
    if (!openCustomerTypes) {
      return undefined
    }

    getCustomerTypes(ignore);

    return () => {
      ignore = true;
    }
  }, [openCustomerTypes]);

  const getCustomerChains = async (ignore) => {
    setLoadingustomerChains(true)
    try {
        const options = {
            method: 'POST',
            url: '/CustomerChains/getCustomerChainsForAutocomplete.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setcustomerChains(response.data);
        }
    setLoadingustomerChains(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;
  
    if (!openCustomerChains) {
      return undefined
    }

    getCustomerChains(ignore);

    return () => {
      ignore = true;
    }
  }, [openCustomerChains]);

  const handleRegionChange = (e, selectValue) => {
    if (selectValue) {
      let region = selectValue.value;
      setEditData((prevAccount) => ({
        ...prevAccount, 
        region: region, 
        region_array: selectValue, 
      }))
    }
  }

  const handleChannelChange = (e, selectValue) => {
    if (selectValue) {
      let channel = selectValue.value;
      setEditData((prevAccount) => ({
        ...prevAccount, 
        channel: channel, 
        channel_array: selectValue, 
      }))
    }
  }

  const handleTermsChange = (e, selectValue) => {
    if (selectValue) {
      let terms = selectValue.value;
      setEditData((prevAccount) => ({
        ...prevAccount, 
        terms: terms, 
        terms_array: selectValue, 
      }))
    }
  }
  const handleDiscountChange = (e, selectValue) => {
    if (selectValue) {
      let discount = selectValue.value;
      setEditData((prevAccount) => ({
        ...prevAccount, 
        discount: discount, 
        discount_array: selectValue, 
      }))
    }
  }

  const handleDaysCoverChange = (e, selectValue) => {
    if (selectValue) {
      let days_cover = selectValue.value;
      setEditData((prevAccount) => ({
        ...prevAccount, 
        days_cover: days_cover, 
        days_cover_array: selectValue, 
      }))
    }
  }

  const handleFrequencyChange = (e, selectValue) => {
    if (selectValue) {
      let frequency = selectValue.value;
      setEditData((prevAccount) => ({
        ...prevAccount, 
        frequency: frequency, 
        frequency_array: selectValue, 
      }))
    }
  }

  const handleActiveChange = (event) => {
    let active = event.target.value;
    setEditData((prevAccount) => ({
      ...prevAccount, 
      active: active, 
    }))
  }

  const handleStoreImageChange = (event) => {
    const file = event.target.files[0];
    setEditData((prev) => ({...prev, store_image: file}));
  };

  const handleBusinessPermitImageChange = (event) => {
    const file = event.target.files[0];
    setEditData((prev) => ({...prev, business_permit_image: file}));
  };

  const handleCustomerIdImageChange = (event) => {
    const file = event.target.files[0];
    setEditData((prev) => ({...prev, customer_id_image: file}));
  };
  const handleProofOfBillingImageChange = (event) => {
    const file = event.target.files[0];
    setEditData((prev) => ({...prev, proof_of_billing: file}));
  };

  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Account</DialogTitle>
        <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Backend info" {...a11yProps(0)} />
            <Tab label="Basic info" {...a11yProps(1)} />
            <Tab label="Documents & images" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    open={openCustomerTypes}
                    onOpen={() => {setOpenustomerTypes(true);}}
                    onClose={() => {setOpenustomerTypes(false);}}
                    loading={loadingCustomerTypes}
                    onChange={handleCustomerTypeChange}
                    options={customerTypes}
                    value={editData.customer_type_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer type*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingCustomerTypes ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} mb={2} mt={2}>
                <TextField
                    name="account_code"
                    label="Customer No (JDE - Syspro)*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, account_code: e.target.value }))}
                    value={editData.account_code}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    open={openCustomerChains}
                    onOpen={() => {setOpenustomerChains(true);}}
                    onClose={() => {setOpenustomerChains(false);}}
                    loading={loadingCustomerChains}
                    onChange={handleCustomerCHainChange}
                    options={customerChains}
                    value={editData.customer_chain_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Distributor/ Chain acct*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingCustomerChains ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  />
                </FormControl>
              </Grid>
            </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="csid"
                  label="CSID (Auto Generated)*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, csid: e.target.value }))}
                  value={editData.csid}
                />
              </Grid>
              <Grid item xs={12} mb={2} mt={2}>
                <TextField
                    name="account_name"
                    label="Store / Business Name*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, account_name: e.target.value }))}
                    value={editData.account_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="business_address"
                  label="Ship to / Business Address*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, business_address: e.target.value }))}
                  value={editData.business_address}
                />
                <Typography variant="body2" color="initial">Unit/Flr + Bldg + Blk/Lot + St. + Subd + Brgy + Municipality/City + Province + ZIP code</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="residential_address"
                  label="Residential Address*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, residential_address: e.target.value }))}
                  value={editData.residential_address}
                />
                <Typography variant="body2" color="initial" >Unit/Flr + Bldg + Blk/Lot + St. + Subd + Brgy + Municipality/City + Province + ZIP code</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="owner_mngr_buyer_name"
                  label="Owner / Manager / Buyer's name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, owner_mngr_buyer_name: e.target.value }))}
                  value={editData.owner_mngr_buyer_name}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  name="contact"
                  label="Contact no.*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, contact: e.target.value }))}
                  value={editData.contact}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleRegionChange}
                    options={region}
                    value={editData.region_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Region*" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleChannelChange}
                    options={channel}
                    value={editData.channel_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Channel*" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} mb={2}>
                <TextField
                  name="distibutor_sales_representative"
                  label="Distributor Sales Representative"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, distibutor_sales_representative: e.target.value }))}
                  value={editData.distibutor_sales_representative}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleTermsChange}
                    options={terms}
                    value={editData.terms_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Terms" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleDiscountChange}
                    options={discount}
                    value={editData.discount_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Discount" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleDaysCoverChange}
                    options={daysCover}
                    value={editData.days_cover_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Days cover" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleFrequencyChange}
                    options={frequency}
                    value={editData.frequency_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Frequency" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Active</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editData.active}
                    label="Active"
                    onChange={handleActiveChange}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <Grid container spacing={2}>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Store Image
                  <VisuallyHiddenInput type="file" accept="application/pdf, image/* " onChange={handleStoreImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {editData.store_image && (
                  <p>{editData.store_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Business Permit Image
                  <VisuallyHiddenInput type="file" accept="application/pdf, image/* " onChange={handleBusinessPermitImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {editData.business_permit_image && (
                  <p>{editData.business_permit_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Customer ID Image
                  <VisuallyHiddenInput type="file" accept="image/* " onChange={handleCustomerIdImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {editData.customer_id_image && (
                  <p>{editData.customer_id_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Proof Of Billing
                  <VisuallyHiddenInput type="file" accept="image/* " onChange={handleProofOfBillingImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {editData.proof_of_billing && (
                  <p>{editData.proof_of_billing.name}</p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CustomTabPanel>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default EditAccounts