import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, Tab, Tabs, TextField, useTheme, Typography, InputLabel, Select, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import { CloudUpload } from '@mui/icons-material';
import styled from '@emotion/styled';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Add({setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const [customerTypes, setcustomerTypes] = useState([]);
  const [loadingCustomerTypes, setLoadingustomerTypes] = useState(false);
  const [openCustomerTypes, setOpenustomerTypes] = useState(false);
  const [customerChains, setcustomerChains] = useState([]);
  const [loadingCustomerChains, setLoadingustomerChains] = useState(false);
  const [openCustomerChains, setOpenustomerChains] = useState(false);

  const region = [
    {'label':'GMA', 'value':'GMA'},
    {'label':'NLA', 'value':'NLA'},
    {'label':'SLA', 'value':'SLA'},
    {'label':'VIS', 'value':'VIS'},
    {'label':'MIN', 'value':'MIN'},
  ];
  
  const channel = [
    {'label': 'Bakery', 'value': 'Bakery'},
    {'label': 'CVS/Petrol', 'value': 'CVS/Petrol'},
    {'label': 'Drugstore', 'value': 'Drugstore'},
    {'label': 'Grocery', 'value': 'Grocery'},
    {'label': 'OOH', 'value': 'OOH'},
    {'label': 'Others - OOH', 'value': 'Others - OOH'},
    {'label': 'Others - Retails', 'value': 'Others - Retails'},
    {'label': 'Sari-Sari Store', 'value': 'Sari-Sari Store'},
    {'label': 'School', 'value': 'School'},
    {'label': 'Street Selling', 'value': 'Street Selling'},
    {'label': 'Sub-Distributor', 'value': 'Sub-Distributor'},
    {'label': 'Supermarket', 'value': 'Supermarket'},
    {'label': 'Foodservice', 'value': 'Foodservice'},
    {'label': 'Others', 'value': 'Others'}
  ];

  const terms = [
    {'label':'Cash on Delivery (COD)', 'value':'Cash on Delivery (COD)'},
    {'label':'7 Days', 'value':'7 Days'},
    {'label':'15 Days', 'value':'15 Days'},
    {'label':'30 Days', 'value':'30 Days'},
  ];

  const discount = [
    {'label':'0%', 'value':'0%'},
    {'label':'5%', 'value':'5%'},
    {'label':'8%', 'value':'8%'},
    {'label':'10%', 'value':'10%'},
  ];

  const daysCover = [
    {'label':'Mon', 'value':'Mon'},
    {'label':'Tue', 'value':'Tue'},
    {'label':'Wed', 'value':'Wed'},
    {'label':'Thu', 'value':'Thu'},
    {'label':'Fri', 'value':'Fri'},
    {'label':'Sat', 'value':'Sat'},
  ];
  const frequency = [
    {'label':'F1', 'value':'F1'},
    {'label':'F2', 'value':'F2'},
    {'label':'F4', 'value':'F4'},
    {'label':'F8', 'value':'F8'},
  ];

  const [addData, setAddData] = useState({
    account_code: '',
    account_name: '',
    description: '',
    customer_type_array: [],
    customer_chain_array: [],
    customer_type: '',
    customer_chain: '',
    csid: '',
    business_address: '',
    residential_address: '',
    owner_mngr_buyer_name: '',
    distibutor_sales_representative: '',
    contact: '',
    region: '',
    region_array: [],
    channel: '',
    channel_array: [],
    terms: '',
    terms_array: [],
    discount: '',
    discount_array: [],
    days_cover: '',
    days_cover_array: [],
    frequency: '',
    frequency_array: [],
    active: 0,
    store_image: '',
    business_permit_image: '',
    customer_id_image: '',
    proof_of_billing: '',
  });

  const resetForm = () => {
    setAddData({
      account_code: '',
      account_name: '',
      description: '',
      customer_type_array: [],
      customer_chain_array: [],
      customer_type: '',
      customer_chain: '',
      csid: '',
      business_address: '',
      residential_address: '',
      owner_mngr_buyer_name: '',
      distibutor_sales_representative: '',
      contact: '',
      region: '',
      region_array: [],
      channel: '',
      channel_array: [],
      terms: '',
      terms_array: [],
      discount: '',
      discount_array: [],
      days_cover: '',
      days_cover_array: [],
      frequency: '',
      frequency_array: [],
      active: 0,
      store_image: '',
      business_permit_image: '',
      customer_id_image: '',
      proof_of_billing: '',
    });
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const validateRequiredFields = () => {
    const requiredFields = ['customer_type', 'account_code', 'customer_chain', 'business_address', 'residential_address', 'account_name', 'owner_mngr_buyer_name', 'contact', 'store_image', 'business_permit_image'];

    for (const field of requiredFields) {
      if (!addData[field] || (Array.isArray(addData[field]) && addData[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }

    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    if (!isValidSpecialCharacter(addData.account_name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Account Name.')
        return
    }

    if (!isValidSpecialCharacter(addData.description) && addData.description !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/ApiAccounts/addAccount.json',
              data: objectToFormData(addData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      console.log(error);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCustomerTypeChange = (e, selectValue) => {
    if (selectValue) {
      setAddData((prevData) => ({ ...prevData, customer_type_array: selectValue, customer_type: selectValue.customer_type}))
    }
  }
  const handleCustomerCHainChange = (e, selectValue) => {
    if (selectValue) {
      setAddData((prevData) => ({ ...prevData, customer_chain_array: selectValue, customer_chain: selectValue.customer_chain}))
    }
  }

  const getCustomerTypes = async (ignore) => {
    setLoadingustomerTypes(true)
    try {
        const options = {
            method: 'POST',
            url: '/CustomerTypes/getCustomerTypesForAutocomplete.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setcustomerTypes(response.data);
        }
    setLoadingustomerTypes(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;
  
    if (!openCustomerTypes) {
      return undefined
    }

    getCustomerTypes(ignore);

    return () => {
      ignore = true;
    }
  }, [openCustomerTypes]);

  const getCustomerChains = async (ignore) => {
    setLoadingustomerChains(true)
    try {
        const options = {
            method: 'POST',
            url: '/CustomerChains/getCustomerChainsForAutocomplete.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setcustomerChains(response.data);
        }
    setLoadingustomerChains(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;
  
    if (!openCustomerChains) {
      return undefined
    }

    getCustomerChains(ignore);

    return () => {
      ignore = true;
    }
  }, [openCustomerChains]);

  const generateCSID = () => {
    const min = 10000000; // 8 digits minimum
    const max = 99999999; // 8 digits maximum
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Auto-generate CSID when component mounts
  useEffect(() => {
    setAddData((prevData) => ({
      ...prevData,
      csid: generateCSID(),
    }));
  }, []);

  const handleRegionChange = (e, selectValue) => {
    if (selectValue) {
      let region = selectValue.value;
      setAddData((prevAccount) => ({
        ...prevAccount, 
        region: region, 
        region_array: selectValue, 
      }))
    }
  }

  const handleChannelChange = (e, selectValue) => {
    if (selectValue) {
      let channel = selectValue.value;
      setAddData((prevAccount) => ({
        ...prevAccount, 
        channel: channel, 
        channel_array: selectValue, 
      }))
    }
  }

  const handleTermsChange = (e, selectValue) => {
    if (selectValue) {
      let terms = selectValue.value;
      setAddData((prevAccount) => ({
        ...prevAccount, 
        terms: terms, 
        terms_array: selectValue, 
      }))
    }
  }
  const handleDiscountChange = (e, selectValue) => {
    if (selectValue) {
      let discount = selectValue.value;
      setAddData((prevAccount) => ({
        ...prevAccount, 
        discount: discount, 
        discount_array: selectValue, 
      }))
    }
  }

  const handleDaysCoverChange = (e, selectValue) => {
    if (selectValue) {
      let days_cover = selectValue.value;
      setAddData((prevAccount) => ({
        ...prevAccount, 
        days_cover: days_cover, 
        days_cover_array: selectValue, 
      }))
    }
  }

  const handleFrequencyChange = (e, selectValue) => {
    if (selectValue) {
      let frequency = selectValue.value;
      setAddData((prevAccount) => ({
        ...prevAccount, 
        frequency: frequency, 
        frequency_array: selectValue, 
      }))
    }
  }

  const handleActiveChange = (event) => {
    let active = event.target.value;
    setAddData((prevAccount) => ({
      ...prevAccount, 
      active: active, 
    }))
  }

  const handleStoreImageChange = (event) => {
    const file = event.target.files[0];
    setAddData((prev) => ({...prev, store_image: file}));
  };

  const handleBusinessPermitImageChange = (event) => {
    const file = event.target.files[0];
    setAddData((prev) => ({...prev, business_permit_image: file}));
  };

  const handleCustomerIdImageChange = (event) => {
    const file = event.target.files[0];
    setAddData((prev) => ({...prev, customer_id_image: file}));
  };
  const handleProofOfBillingImageChange = (event) => {
    const file = event.target.files[0];
    setAddData((prev) => ({...prev, proof_of_billing: file}));
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <div>
      <Button 
        variant="contained" size="small"
        onClick={(e) => {handleOpen(e)}}>
      Add
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Add new Customer</DialogTitle>
        <DialogContent>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Backend info" {...a11yProps(0)} />
            <Tab label="Basic info" {...a11yProps(1)} />
            <Tab label="Documents & images" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    open={openCustomerTypes}
                    onOpen={() => {setOpenustomerTypes(true);}}
                    onClose={() => {setOpenustomerTypes(false);}}
                    loading={loadingCustomerTypes}
                    onChange={handleCustomerTypeChange}
                    options={customerTypes}
                    value={addData.customer_type_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer type*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingCustomerTypes ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} mb={2} mt={2}>
                <TextField
                    name="account_code"
                    label="Customer No (JDE - Syspro)*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setAddData((prevAccount) => ({ ...prevAccount, account_code: e.target.value }))}
                    value={addData.account_code}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    open={openCustomerChains}
                    onOpen={() => {setOpenustomerChains(true);}}
                    onClose={() => {setOpenustomerChains(false);}}
                    loading={loadingCustomerChains}
                    onChange={handleCustomerCHainChange}
                    options={customerChains}
                    value={addData.customer_chain_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Distributor/ Chain acct*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingCustomerChains ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  />
                </FormControl>
              </Grid>
            </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="csid"
                  label="CSID (Auto Generated)*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddData((prevAccount) => ({ ...prevAccount, csid: e.target.value }))}
                  value={addData.csid}
                />
              </Grid>
              <Grid item xs={12} mb={2} mt={2}>
                <TextField
                    name="account_name"
                    label="Store / Business Name*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setAddData((prevAccount) => ({ ...prevAccount, account_name: e.target.value }))}
                    value={addData.account_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="business_address"
                  label="Ship to / Business Address*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddData((prevAccount) => ({ ...prevAccount, business_address: e.target.value }))}
                  value={addData.business_address}
                />
                <Typography variant="body2" color="initial">Unit/Flr + Bldg + Blk/Lot + St. + Subd + Brgy + Municipality/City + Province + ZIP code</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="residential_address"
                  label="Residential Address*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddData((prevAccount) => ({ ...prevAccount, residential_address: e.target.value }))}
                  value={addData.residential_address}
                />
                <Typography variant="body2" color="initial" >Unit/Flr + Bldg + Blk/Lot + St. + Subd + Brgy + Municipality/City + Province + ZIP code</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="owner_mngr_buyer_name"
                  label="Owner / Manager / Buyer's name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddData((prevAccount) => ({ ...prevAccount, owner_mngr_buyer_name: e.target.value }))}
                  value={addData.owner_mngr_buyer_name}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  name="contact"
                  label="Contact no.*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddData((prevAccount) => ({ ...prevAccount, contact: e.target.value }))}
                  value={addData.contact}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleRegionChange}
                    options={region}
                    value={addData.region_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Region*" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleChannelChange}
                    options={channel}
                    value={addData.channel_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Channel*" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} mb={2}>
                <TextField
                  name="distibutor_sales_representative"
                  label="Distributor Sales Representative"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddData((prevAccount) => ({ ...prevAccount, distibutor_sales_representative: e.target.value }))}
                  value={addData.distibutor_sales_representative}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleTermsChange}
                    options={terms}
                    value={addData.terms_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Terms" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleDiscountChange}
                    options={discount}
                    value={addData.discount_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Discount" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleDaysCoverChange}
                    options={daysCover}
                    value={addData.days_cover_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Days cover" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={handleFrequencyChange}
                    options={frequency}
                    value={addData.frequency_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Frequency" />}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Active</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addData.active}
                    label="Active"
                    onChange={handleActiveChange}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <Grid container spacing={2}>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Store Image*
                  <VisuallyHiddenInput type="file" accept="application/pdf, image/* " onChange={handleStoreImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {addData.store_image && (
                  <p>{addData.store_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Business Permit Image*
                  <VisuallyHiddenInput type="file" accept="application/pdf, image/* " onChange={handleBusinessPermitImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {addData.business_permit_image && (
                  <p>{addData.business_permit_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Customer ID Image
                  <VisuallyHiddenInput type="file" accept="image/* " onChange={handleCustomerIdImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {addData.customer_id_image && (
                  <p>{addData.customer_id_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Proof Of Billing
                  <VisuallyHiddenInput type="file" accept="image/* " onChange={handleProofOfBillingImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {addData.proof_of_billing && (
                  <p>{addData.proof_of_billing.name}</p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CustomTabPanel>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default Add