import { Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData, validateAlphaNumeric } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { useTheme } from '@emotion/react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function EditRequest({requestDetails, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [stores, setStores] = useState([]);
  const [deploymentTypes, setDeploymentTypes] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetSize, setAssetSize] = useState([]);
  const [loadingStore, setLoadingStore] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [editData, setEditData] = useState({
    delivery_date: [],
    deployment_type: [],
    asset_size: [],
    asset_type: [],
    store_account: [],
    store_contact_no: '',
    store_contact_name: '',
    remarks: '',
    signature_image: '',
    talaf_file: '',
    account_code: '',
    asset_size_code: '',
    unique_code: '',
    asset_type_code: '',
    warehouseSelected: [], 
    warehouse: [],
    warehouse_unique_code: '', 
    warehouse_name: '',
    request_code: '',
  });

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);

    const { expected_delivery_date, warehouse_name, warehouse_unique_code, asset_size_code, asset_size_name, id, asset_name, branch_code, branch_name,account_code, account_name,  remarks, asset_type_code, asset_type_name, deployment_type_code, deployment_type_name, branch_contact_person, branch_contact_number, request_code} = requestDetails;
    setEditData((prevData) => ({ 
      ...prevData, 
      asset_size: {'label': asset_size_name, 'value': asset_size_code}, 
      asset_type: {'label': asset_type_name, 'value': asset_type_code}, 
      deployment_type: {'label': deployment_type_name, 'value': deployment_type_code}, 
      store_account: {'label': `${account_code} - (${account_name})`, 'account_code': account_code}, 
      warehouseSelected: {'label': `${warehouse_name} - (${warehouse_unique_code})`, 'value': warehouse_unique_code,}, 
      warehouse_unique_code: warehouse_unique_code,
      warehouse_name: warehouse_name,
      id: id,
      delivery_date: expected_delivery_date,
      asset_name: asset_name,
      account_code: account_code,
      account_name: account_name,
      branch_code: branch_code,
      branch_name: branch_name,
      remarks: remarks,
      unique_code: deployment_type_code,
      deployment_type_name: deployment_type_name,
      store_contact_name: branch_contact_person,
      store_contact_no: branch_contact_number,
      asset_size_name: asset_size_name,
      asset_size_code: asset_size_code,
      asset_type_code: asset_type_code,
      request_code: request_code,
    }))
  }

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      delivery_date: [],
      deployment_type: [],
      asset_size: [],
      asset_type: [],
      store_account: [],
      store_contact_no: '',
      store_contact_name: '',
      remarks: '',
      signature_image: '',
      talaf_file: '',
      account_code: '',
      asset_size_code: '',
      unique_code: '',
      asset_type_code: '',
      warehouseSelected: [], 
      warehouse: [],
      warehouse_unique_code: '', 
      warehouse_name: '',
      request_code: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    if (!isValidSpecialCharacter(editData.store_contact_name)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Store Contact Name.')
      return
    }
    if (!isValidSpecialCharacter(editData.remarks) && editData.remarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Remarks.')
      return
    }

    // if (!editData.talaf_file) {
    //   setOpenMessageDialog(true);
    //   setMessage('Please upload TALAF documents')
    //   return
    // }

    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/DeploymentRequests/editRequest.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const getAssetSizes = async (ignore) => {
    try {
        const options = {
            method: 'POST',
            url: '/AssetSizes/getAssetSizesForRequest.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setAssetSize(response.data);
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getDeploymentTypes = async (ignore) => {
    try {
        const options = {
            method: 'POST',
            url: '/DeploymentTypes/getDeploymentTypesForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setDeploymentTypes(response.data);
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetTypes = async (ignore) => {
    try {
        
        const options = {
            method: 'POST',
            url: '/AssetTypes/getAssetTypeNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetTypes(response.data);
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getStores = async (ignore) => {
    try {
      setLoadingStore(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getAllStoresForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setStores(response.data);
        }
        
        setLoadingStore(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }


  const handleDeploymentTypeChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, deployment_type: selectValue, unique_code: selectValue.unique_code}))
    }
  }

  const handleAssetSizeChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, asset_size: selectValue, asset_size_code: selectValue.asset_size_code}))
    }
  }

  const handleAssetTpyesChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, asset_type: selectValue, asset_type_code: selectValue.asset_type_code}))
    }
  }
  const handleStoreAccontsChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, store_account: selectValue, account_code: selectValue.account_code}))
    }
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setEditData(
        (prevAccount) => ({ ...prevAccount, warehouseSelected: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    } 

    setEditData((prevAccount) => ({ ...prevAccount, warehouseSelected: [], warehouse: []}))
  }


  const validateRequiredFields = () => {
    const requiredFields = ['delivery_date', 'deployment_type', 'asset_size', 'asset_type', 'store_account', 'store_contact_no'];

    for (const field of requiredFields) {
      if (!editData[field] || (Array.isArray(editData[field]) && editData[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }

    }

    return true;
  };

  useEffect(()=>{
    let ignore = false;

    getAssetSizes(ignore);
    getDeploymentTypes(ignore)
    getAssetTypes(ignore)
    getStores(ignore)

    return () => {
      ignore = true;
    }
  }, []);

  const handleTALAFChange = (event) => {
    const file = event.target.files[0];
    setEditData((prev) => ({...prev, talaf_file: file}));
  };

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  return (
    <div>
    <Button 
      disabled={(requestDetails.status_code !== 0 || requestDetails.status_code !== 1) ? true : false}
      variant="outlined" 
      size="small" 
      sx={{color: theme.palette.secondary.dark}} 
      onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Edit Asset</DialogTitle>
      <DialogContent>
        <Box sx={{marginBottom: 2 }}>
            <Chip label="Asset Details" size="small" color="secondary" />
        </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Expected Delivery Date*"
                            value={editData.delivery_date ? dayjs(editData.delivery_date) : null}
                            onChange={(newValue) => setEditData({...editData, delivery_date: dayjs(newValue).format('YYYY-MM-DD')})}
                            minDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleAssetTpyesChange}
                    options={assetTypes}
                    value={editData.asset_type}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Asset Type*" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleDeploymentTypeChange}
                    options={deploymentTypes}
                    value={editData.deployment_type}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Deployment Type*" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleAssetSizeChange}
                    options={assetSize}
                    value={editData.asset_size}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Asset Size*" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openWarehouse}
                onOpen={() => {setOpenWarehouse(true);}}
                onClose={() => {setOpenWarehouse(false);}}
                loading={warehouseLoading}
                onChange={hanldeWarehouseChange}
                options={warehouses}
                value={editData.warehouseSelected}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Warehouse Branch*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          </Grid>
            <Box sx={{marginBottom: 2, marginTop: 2}}>
                <Chip label="Customer Details and Agreement" size="small" color="secondary" />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={handleStoreAccontsChange}
                        options={stores}
                        loading={loadingStore}
                        value={editData.store_account}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Store*"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingStore ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={2} >
                  <Grid item>
                    <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                      Upload TALAF
                      <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleTALAFChange}/>
                    </Button>
                  </Grid>
                  <Grid item>
                    {editData.talaf_file && (
                      <p>{editData.talaf_file.name}</p>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Store Contact Person*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, store_contact_name: e.target.value }))}
                    value={editData.store_contact_name}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Store contact no."
                    fullWidth
                    variant="standard"
                    onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, store_contact_no: e.target.value }))}
                    value={editData.store_contact_no}
                />
                </Grid>
                
            </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default EditRequest