import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Close, CloudUpload } from '@mui/icons-material';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, trimWhitespaces } from '../GenericFunctions/GenericFunctions';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function AddDisposalRequest({assetCode, setOnEdit}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [disposalRequests, setDisposalRequests] = useState({
    assetSelected: [],
    assets: [],
    asset_image: [],
    remarks: ''
  });
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      let assets = selectValue.map(item => item.asset_code);
      return setDisposalRequests(
        (prevAccount) => ({ ...prevAccount, assetSelected: selectValue, assets: assets})
      )
    } 

    setDisposalRequests((prevAccount) => ({ ...prevAccount, assetSelected: [], assets: []}))
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setDisposalRequests((prevAccount) => ({ ...prevAccount, asset_image: [...prevAccount.asset_image, ...files]}))
  };

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = disposalRequests.asset_image.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setDisposalRequests(prevState => ({
      ...prevState,
      asset_image: updatedImages
    }));
  };

  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAssetsForDisposal.json',
            'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openAssets) {
      setAssets([]);
    }

    if (!openAssets) return undefined
    getAssets(ignore)

    return () => {
      ignore = true
    };
  }, [openAssets]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const trimmedremarks = trimWhitespaces(disposalRequests.remarks)

    if (disposalRequests.assets.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Asset for Disposal is required.');
      return false;
    }

    if (disposalRequests.asset_image.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Please upload atleast 1 image.')
      return
    }

    if (!isValidSpecialCharacter(trimmedremarks) && trimmedremarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in remarks.')
      return
    }

    try {
      const formData = new FormData();
      if (disposalRequests.asset_image.length >  0) {
        disposalRequests.asset_image.forEach((file, index) => {
          formData.append(`asset_image_${index}`, file);
        });
      }

      // Add other fields to formData if needed
      formData.append('assets', disposalRequests.assets);
      formData.append('remarks', disposalRequests.remarks);

      const options = {
          method: 'POST',
            url: '/AssetsDisposal/addRequest.json',
            data: formData,
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setOnEdit((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setDisposalRequests({
      assetSelected: [],
      assets: [],
      asset_image: [],
      remarks: ''
    });
  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>Create Request</Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >
      <DialogTitle>Add New Disposal request</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="combo-box-demo"
                open={openAssets}
                onOpen={() => {setOpenAssets(true);}}
                onClose={() => {setOpenAssets(false);}}
                loading={loading}
                onChange={hanldeAssetsChange}
                options={assets}
                value={assets.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Asset for Disposal*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} mb={2}>
              <Typography mb={1}>Scrap asset picture:</Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                >
                Upload images
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {disposalRequests.asset_image.length > 0 && (
                <>
                  <Typography mt={2}>Selected Image:</Typography>
                  <ul>
                    {disposalRequests.asset_image.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Input Remarks" 
                  onChange={(e) => setDisposalRequests((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                />
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default AddDisposalRequest