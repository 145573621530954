import { Box, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import useCommentsContext from '../../hooks/UseCommentsContext';
import AddComments from './AddComments';
import useAuth from '../../hooks/UseAuth';


function AccountComments({customerNo}) {
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const {doneUpdate} = useCommentsContext();
  const [deploymentRequests, setDeploymentRequests] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'comments', headerName: 'comments',flex: 1, minWidth: 300, wrap: true},
    { field: 'created', headerName: 'Date Added',flex: 1, minWidth: 100,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150},
    { field: 'username', headerName: 'Username',flex: 1, minWidth: 250, wrap: true},
  ];
  useEffect(() => {
    let ignore = false;
    const getAssetTypes = async (ignore) => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/ApiAccounts/getComments.json',
          'data' : {'customer_no': customerNo}
        }
        const response = await axios(options);
        const data = response.data;
        if (!ignore) {
          setDeploymentRequests(data)
        }
        
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      } finally {
        setLoading(false);
      }

      return () => {
        ignore = true;
        setLoading(false);
      }
    }

    getAssetTypes(ignore);
  }, [onEditOrDelete, doneUpdate]);
  return (
    <div>
    <Box sx={{ marginBottom: '20px' }}>
    <Typography variant="h6" component="div" sx={{ flexGrow: 1}} >Comments </Typography>
      {Number(auth.role) === 3 || Number(auth.role) === 2 || Number(auth.role) === 0 ? (
        <AddComments customerNo={customerNo}/> 
      ) : ''}
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows= {deploymentRequests}
        
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        getRowHeight={() => 'auto'}
        autoHeight
        keepNonExistentRowsSelected
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </div>
  )
}

export default AccountComments