import { Avatar, Box, Chip, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import MessageDialog from '../Utils/MessageDialog'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import useStoreToStoreContext from '../../hooks/UseStoreToStoreContext';


function OriginAssetStore({asset_code, requestCode}) {
  const axios = AxiosCustomConfig();
  const {doneUpdate} = useStoreToStoreContext();
  const [assets, setAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'account_code', headerName: 'Account Code', flex: 1, minWidth: 100, wrap: true,},
    { field: 'account_name', headerName: 'Account Name', width: 150,},
    { field: 'account_name', headerName: 'Store name', flex: 1, minWidth: 100, wrap: true, },
    { 
        field: 'status', 
        headerName: 'Status',
        flex: 1, 
        minWidth: 100, 
        wrap: true,
        renderCell: (params) => {
          
            const statusName = params.value;
            if (statusName === 0) {
              return <Chip label='In store' color='info' />;
            } else if (statusName === 1) {
              return <Chip label='Pulled out' color='error' />;
            } else {
              return '';
            }
            
        }
    },
  ];
  const getAssets = async (ignore) => {
    try {
      setLoading(true);
      const options = {
        'method': 'POST',
        'url' : '/StoreToStoreTransfers/getOriginStoreAsset.json',
        'data' : {asset_code : asset_code, request_code: requestCode}
      }
      const response = await axios(options);
      const data = response.data;
      if(!ignore) {
        if (Object.keys(data).length > 0) {
            setAssets([data]);
        }
        setLoading(false);
      }
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      if(!ignore) {
        setLoading(false);
      }
      
    }
  }

  useEffect(() => {
    let ignore = false
    getAssets(ignore)
    return ()=>{ignore=true} 
  }, [doneUpdate]);

  return (
    <Box>
    <Box p={1}>
      <Typography>Origin Store</Typography>
    </Box>
    <Box sx={{width: '100%', height: 100, display:'table', tableLayout: 'fixed'}} >
    {assets && 
      <DataGrid
        autoHeight
        keepNonExistentRowsSelected
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={assets ? assets : []}
        columns={columns}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
        />
      }
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </Box>
  )
}


export default OriginAssetStore