import axios from 'axios';

const AxiosCustomConfig = () => {
  const instance = axios.create({
    // baseURL: 'http://localhost:8087/api',
    baseURL: 'https://assetrakker-dev.tradynamics.com/api',
    // Remove comment below if you are using local tunnel to access this application on the internet
    headers: {
      'Bypass-Tunnel-Reminder': 'true',
    },
  });

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  "Bearer " + token;
    return config;
  });

  // Add a response interceptor
  instance.interceptors.response.use(function (response) {
    const authorizationHeadears = response.config.headers.Authorization
    const tokenArray = authorizationHeadears.split(' ')
    const token = tokenArray[1];
    localStorage.setItem('token', token);

    return response;
  }, function (error) {
    console.log('error', error);
      // get new token from isTokenValid.json
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

  return instance;
};

const AxiosCustomConfigMFA = () => {
  const instance = axios.create({
    // baseURL: 'http://localhost:8087/api',
    baseURL: 'https://assetrakker-dev.tradynamics.com:444/api',
    // Remove comment below if you are using local tunnel to access this application on the internet
    headers: {
      'Bypass-Tunnel-Reminder': 'true',
      'Content-Type': 'application/json',
    },
  });

  return instance;
};

export { AxiosCustomConfig, AxiosCustomConfigMFA };
