
import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Grid, Paper, StyledEngineProvider, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Chip, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, } from '@mui/material';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useAuth from '../../hooks/UseAuth';
import { format } from 'date-fns';
import { TransferAssetRequestProvider } from '../../context/TransferAssetRequestProvider';
import { ArrowDownward, Close, Download } from '@mui/icons-material';
import Assets from './Assets';
import AssetComments from '../AssetRegistry/AssetComments';
import AccountDeploymentRequest from './AccountDeploymentRequest';
import AccountPulloutRequest from './AccountPulloutRequest';
import AccountServiceRequest from './AccountServiceRequest';
import AccountComments from './AccountComments';
import EditAccounts from './EditAccounts';
import ChangeCustomerDetails from './ChangeCustomerDetails';
import ChangeDistributorAccount from './ChangeDistributorAccount';
import UpdateChannel from './UpdateChannel';
import Deactivate from './Deactivate';

const components = {
  Assets,
  AccountDeploymentRequest,
  AccountPulloutRequest,
  AccountServiceRequest,
};

const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);
const ViewAccounts = () => {
  const { requestCode, customerNo } = useParams();
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [doneUpdateRequest, setDoneUpdateRequest] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [fileType, setFileType] = useState('');
  const [content, setContent] = useState('');
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);

  const [Accounts, setAccounts] = useState({});
  const [openPermit, setOpenPermit] = useState(false);
  const [openCustomerId, setOpenCustomerId] = useState(false);
  const [openProofBilling, setOpenProofBilling] = useState(false);

  const handleClickOpenStoreImage = () => {
    setOpen(true);
  };

  const handleCloseStoreImage = () => {
    setOpen(false);
  };
  const handleClickOpenPermit = () => {
    setOpenPermit(true);
  };

  const handleClosePermit = () => {
    setOpenPermit(false);
  };
  const handleClickOpenCustomerId = () => {
    setOpenCustomerId(true);
  };

  const handleCloseCustomerId = () => {
    setOpenCustomerId(false);
  };
  const handleClickOpenProofBilling = () => {
    setOpenProofBilling(true);
  };

  const handleCloseProofBilling= () => {
    setOpenProofBilling(false);
  };

  const getAssets = async () => {
    try {
      setLoading(true);
      const options = {
          'method': 'POST',
          'url' : '/ApiAccounts/getViewAccounts.json',
          'data': {'customer_no': customerNo}
      }
      const response = await axios(options);
      const data = response.data;
      setAccounts(data);
      setLoading(false);
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAssets();
  }, [onEditOrDelete]);

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const accordionData = [
    { id: 1, title: "Assets", component: "Assets" },
    { id: 2, title: "Deployment Request ", component: "AccountDeploymentRequest" },
    { id: 3, title: "Pullout Request", component: "AccountPulloutRequest" },
    { id: 4, title: "Service Call Request", component: "AccountServiceRequest" },
    // Add more data as needed
  ];

  const initialExpandedState = accordionData.map((_, index) => index < 2); // First two true, rest false
  const [expanded, setExpanded] = useState(initialExpandedState);
  const [hasFetched, setHasFetched] = useState(initialExpandedState);

  const handleChange = (index) => () => {
    const newExpanded = expanded.map((item, i) => (i === index ? !item : item));
    setExpanded(newExpanded);

    if (!hasFetched[index]) {
      fetchData(index);
    }
  };

  const fetchData = (index) => {
    const newHasFetched = hasFetched.map((item, i) => (i === index ? true : item));
    setHasFetched(newHasFetched);
  };

  return (
    <>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/customers">
            Customers
          </Link>
          <Typography color="text.primary">{Accounts?.store_name || 'No data found'}</Typography>
        </Breadcrumbs>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : Accounts ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ArrowDownward />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h6">{Accounts.store_name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <EditAccounts accountsData={Accounts} setOnEditOrDelete={setOnEditOrDelete}/>
                    </Grid>
                    <Grid item>
                      <ChangeCustomerDetails accountsData={Accounts} setOnEditOrDelete={setOnEditOrDelete}/>
                    </Grid>
                    <Grid item>
                      <ChangeDistributorAccount accountsData={Accounts} setOnEditOrDelete={setOnEditOrDelete}/>
                    </Grid>
                    <Grid item>
                      <Deactivate accountsData={Accounts} setOnEditOrDelete={setOnEditOrDelete}/>
                    </Grid>
                    <Grid item>
                      <UpdateChannel accountsData={Accounts} setOnEditOrDelete={setOnEditOrDelete}/>
                    </Grid>
                  </Grid>
                  
                  <Box mb={2}>
                    <Typography 
                        variant="body1" 
                    >
                     Store image:
                    </Typography>
                    <img 
                      src={Accounts.store_image_url} 
                      alt="Store" 
                      style={{ width: '100%', height: '100%', cursor: 'pointer' }} 
                      onClick={handleClickOpenStoreImage} 
                    />
                  </Box>
                  <Box mb={2}>
                    <Typography 
                        variant="body1" 
                    >
                     Business permit image:
                    </Typography>
                    <img 
                      src={Accounts.business_permit_image_url} 
                      alt="Store" 
                      style={{ width: '100%', height: '100%', cursor: 'pointer' }} 
                      onClick={handleClickOpenPermit} 
                    />
                  </Box>
                  <Box mb={2}>
                    <Typography 
                        variant="body1" 
                    >
                     Customer id image:
                    </Typography>
                    <img 
                      src={Accounts.customer_id_image_url} 
                      alt="Store" 
                      style={{ width: '100%', height: '100%', cursor: 'pointer' }} 
                      onClick={handleClickOpenCustomerId} 
                    />
                  </Box>
                  <Box mb={2}>
                    <Typography 
                        variant="body1" 
                    >
                     Proof of billing image:
                    </Typography>
                    <img 
                      src={Accounts.proof_of_billing_url} 
                      alt="Store" 
                      style={{ width: '100%', height: '100%', cursor: 'pointer' }} 
                      onClick={handleClickOpenProofBilling} 
                    />
                  </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {accordionData.map((item, index) => {
                const Component = components[item.component];
                return (
                  <Grid item key={item.id}>
                    <Accordion expanded={expanded[index]} onChange={handleChange(index)}>
                      <AccordionSummary
                        expandIcon={<ArrowDownward />}
                        aria-controls={`panel${item.id}-content`}
                        id={`panel${item.id}-header`}
                      >
                        <Typography variant="h6">{item.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!loading && Component && hasFetched[index] ? (
                          <Component customerNo={customerNo} />
                        ) : null}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}

              <Grid item>
                <Paper elevation={1} style={{ height: '100%', padding: '8px' }}>
                  {!loading && <AccountComments customerNo={customerNo} />}
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={1}>
              <Typography p={2}>Info</Typography>
              <StyledTable sx={{ width: '100%' }} size="small">
                <TableHead></TableHead>
                <TableBody>
                  {Object.entries(Accounts)
                    .filter(
                      ([key]) =>
                        key !== 'branch_code' &&
                        key !== 'store_name' && 
                        key !== 'distributor_company_id' &&
                        key !== 'business_permit_image' &&
                        key !== 'store_image' &&
                        key !== 'customer_id_image' &&
                        key !== 'proof_of_billing' &&
                        key !== 'store_image_url' &&
                        key !== 'business_permit_image_url' &&
                        key !== 'customer_id_image_url' &&
                        key !== 'proof_of_billing_url'
                    )
                    .map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          <strong>
                            {key === 'business_address'
                              ? 'Ship to / Business Address'
                              : key === 'customer_no'
                              ? 'Customer No (JDE - Syspro)'
                              : key === 'owner_mngr_buyer_name'
                              ? "Owner / Manager / Buyer's name"
                              : key === 'distributor_account'
                              ? 'Distributor/ Account'
                              : formatKey(key)}
                            :
                          </strong>
                        </TableCell>
                        <TableCell>
                          {key === 'created' || key === 'modified' ? (
                            value !== null
                              ? format(value, 'yyyy-MM-dd HH:mm:ss')
                              : ''
                          ) : key === 'active' ? (
                            value === 1 ? 'Yes' : 'No'
                          ) : (
                            value
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </StyledTable>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h6" color="text.secondary">
          No data found
        </Typography>
      )}
      
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose={() => {
            setOpenMessageDialog(false);
          }}
          message={message}
        />
      </StyledEngineProvider>

      <Dialog open={open} onClose={handleCloseStoreImage} maxWidth="md">
        <IconButton aria-label="close" style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCloseStoreImage}>
            <Close />
        </IconButton>
        <DialogContent>
          <img 
            src={Accounts.store_image_url} 
            alt="Store" 
            style={{ width: '600px', height: '400px' }} 
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openPermit} onClose={handleClosePermit} maxWidth="md">
      <IconButton aria-label="close" style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClosePermit}>
            <Close />
        </IconButton>
        <DialogContent>
          <img 
            src={Accounts.business_permit_image_url} 
            alt="Store" 
            style={{ width: '600px', height: '400px' }} 
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openCustomerId} onClose={handleCloseCustomerId} maxWidth="md">
      <IconButton aria-label="close" style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCloseCustomerId}>
            <Close />
        </IconButton>
        <DialogContent>
          <img 
            src={Accounts.customer_id_image_url} 
            alt="Store" 
            style={{ width: '600px', height: '400px' }} 
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openProofBilling} onClose={handleCloseProofBilling} maxWidth="md">
      <IconButton aria-label="close" style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCloseProofBilling}>
            <Close />
        </IconButton>
        <DialogContent>
          <img 
            src={Accounts.proof_of_billing_url} 
            alt="Store" 
            style={{ width: '600px', height: '400px' }} 
          />
        </DialogContent>
      </Dialog>
    </>
  );

}

export default ViewAccounts