import { useEffect, useState } from "react";
import { createTheme, ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Redirect, Switch, Routes, Navigate, useNavigate } from 'react-router-dom';
import SignIn from "./components/signIn/SignIn";
import { AxiosCustomConfig } from "./config/AxiosCustomConfig";
import MainDrawer from "./components/MainDrawer";
import axios from "axios";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Dashboard from "./components/Dashboard/Dashboard";
import Users from "./components/Users/Users";
import { PrincipalAccounts } from "./components/PrincipalAccounts/PrincipalAccounts";
import useAuth from "./hooks/UseAuth";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Unauthorized from "./components/Unauthorized";
import GeneralSettings from "./components/Settings/GeneralSettings";
import TFAEmailVerification from './components/auth/TFAEmailVerification';
import MFASign from './components/auth/MFASignIn'
import Accounts from "./components/Accounts/Accounts";
import AssetTypes from "./components/AssetTypes/AssetTypes";
import AssetRegistry from "./components/AssetRegistry/AssetRegistry";
import AssetStatus from "./components/AssetStatus/AssetStatus";
import Warehouse from "./components/Warehouse/Warehouse";
import DeploymentRequest from "./components/DeploymentRequest/DeploymentRequest";
import RequestDetails from "./components/DeploymentRequest/RequestDetails";
import AssetSizes from "./components/AssetSizes/AssetSizes";
import PulloutRequests from "./components/PulloutRequests.js/PulloutRequests";
import PulloutTypes from "./components/PulloutTypes/PulloutTypes";
import PulloutRequestDetails from "./components/PulloutRequests.js/PulloutRequestDetails";
import StoreAssets from "./components/StoreAssets/StoreAssets";
import HaulingRate from "./components/HaulingRate/HaulingRate";
import JobOrders from "./components/JobOrders/JobOrders";
import JobOrderDetails from "./components/JobOrders/JobOrderDetails";
import AssetServices from "./components/AssetServices/AssetServices";
import RepairStatus from "./components/RepairStatus/RepairStatus";
import ShopRepair from "./components/ShopRepair/ShopRepair";
import ShopRepairDetails from "./components/ShopRepair/ShopRepairDetails";
import AssetDisposal from "./components/AssetDisposal/AssetDisposal";
import AssetDisposalDetails from "./components/AssetDisposal/AssetDisposalDetails";
import ServiceRequest from "./components/ServiceRequest/ServiceRequest";
import ServiceRequestDetail from "./components/ServiceRequest/ServiceRequestDetail";
import StoreToStoreTransfers from "./components/StoreToStoreTransfers/StoreToStoreTransfers";
import StoreToStoreTransfersDetails from "./components/StoreToStoreTransfers/StoreToStoreTransfersDetails";
import TransferAssetsWarehouse from "./components/TransferAssetsWarehouse/TransferAssetsWarehouse";
import TransferAssetsWarehouseDetails from "./components/TransferAssetsWarehouse/TransferAssetsWarehouseDetails";
import AuditAssetAgent from "./components/AuditAssetAgent/AuditAssetAgent";
import WarehouseAudit from "./components/WarehouseAudit/WarehouseAudit";
import WarehouseAssignments from "./components/WarehouseAssignments/WarehouseAssignments";
import DeploymentTypes from "./components/DeploymentTypes/DeploymentTypes";
import AssetBrands from "./components/AssetBrands/AssetBrands";
import AssetModels from "./components/AssetModels/AssetModels";
import RepairTypes from "./components/RepairTypes/RepairTypes";
import AssetReports from "./components/AssetReports/AssetReports";
import AssetDetails from "./components/ServiceRequest/AssetDetails";
import AssetReportDetails from "./components/AssetReports/AssetReportDetails";
import ViewAsset from "./components/AssetRegistry/ViewAsset";
import NotFound from "./components/NotFound";
import OtherChargesList from "./components/TransferAssetsWarehouse/OtherChargesList";
import ViewOtherCharges from "./components/TransferAssetsWarehouse/ViewOtherCharges";
import CustomerTypes from "./components/CustomerTypes/CustomerTypes";
import CustomerChains from "./components/CustomerChains/CustomerChains";
import ViewAccounts from "./components/Accounts/ViewAccounts";

const theme = createTheme({
  palette:{
    primary:{
      main:'#34495e',
      light:'#ecf0f1',
      dark:'#2c3e50',
      contrastText:'#EDF1F2'
    },
    secondary:{
      main:'#287FB8',
      light:'#3398DB',
      dark:'#3398DB',
      contrastText:'#EDF1F2'
    },
    error: {
      main: '#E74D3C',
    },
    background: {
      default: '#F3F4F9', // Change this color to your desired background color
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },

  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#F3F4F9', 
        },
        columnHeader: {
          backgroundColor: '#FFFFFF', // Change this color to customize column header background
          // Add any other custom styles for column headers here
        },
        cell: {
          backgroundColor: '#FFFFFF', // Change this color to customize cell background
          // Add any other custom styles for cells here
        },
      },
    },
  },
})

const ROLE = {
  'SuperUser' : 0,
  'SystemAdmin' : 1,
  'Principal' : 2,
  'Admin' : 3,
  'Distributor' : 4,
  'Approver' : 5,
  'Hauler' : 6,
}

function App() {
  const { auth } = useAuth();
  
  return (
      <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Routes>
        <Route path="/login" element={(auth?.token) ? <Navigate to="/dashboard" replace /> : <SignIn />} />
        <Route path="/tfaEmailVerification/:id/:token" element={<TFAEmailVerification />} />
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Layout />}> 
              {/* auth required routes */}
              <Route path="/" element={<Navigate to="/dashboard" replace />}  />
              <Route path="mfa" element={<MFASign />} />

              <Route element={<MainDrawer />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="users" element={<Users />}/>
                <Route path="customers" element={<Accounts />}/>
                <Route path="customers/:customerNo" element={<ViewAccounts />}/>
                <Route path="deploymentRequest" element={<DeploymentRequest />}/>
                <Route path="pulloutRequest" element={<PulloutRequests />}/>
                <Route path="storeAssets" element={<StoreAssets />}/>
                <Route path="serviceCallRequests" element={<ServiceRequest />}/>
                <Route path="storeToStoreTransfers" element={<StoreToStoreTransfers />}/>
                <Route path="auditAsset" element={<AuditAssetAgent />}/>

                <Route element={<ProtectedRoutes allowedRoles={[1]} />}>
                  <Route path="principalAccounts" element={<PrincipalAccounts/>}/>
                </Route>

                <Route element={<ProtectedRoutes allowedRoles={[ROLE.SystemAdmin, ROLE.Principal, ROLE.Admin, ROLE.Hauler, ROLE.SuperUser]} />}>
                  <Route path="asset/types" element={<AssetTypes />}/>
                  <Route path="asset/registry" element={<AssetRegistry />}/>
                  <Route path="asset/status" element={<AssetStatus />}/>
                  <Route path="asset/sizes" element={<AssetSizes />}/>
                  <Route path="warehouse" element={<Warehouse />}/>
                  <Route path="pulloutTypes" element={<PulloutTypes />}/>
                  <Route path="haulingRate" element={<HaulingRate />}/>
                  <Route path="assetServices" element={<AssetServices />}/>
                  <Route path="repairStatus" element={<RepairStatus />}/>
                  <Route path="deploymentTypes" element={<DeploymentTypes />}/>
                  <Route path="asset/brands" element={<AssetBrands />}/>
                  <Route path="asset/models" element={<AssetModels />}/>
                  <Route path="repairTypes" element={<RepairTypes />}/>
                  <Route path="customerTypes" element={<CustomerTypes />}/>
                  <Route path="customerChains" element={<CustomerChains />}/>
                  <Route path="asset/:id" element={<ViewAsset />}/>
                </Route>

                <Route element={<ProtectedRoutes allowedRoles={[ROLE.SystemAdmin, ROLE.Principal, ROLE.Admin, ROLE.Hauler, ROLE.Approver, ROLE.SuperUser]} />}>
                  <Route path="shopRepair" element={<ShopRepair />}/>
                  <Route path="jobOrders" element={<JobOrders />}/>
                  <Route path="shopRepair" element={<ShopRepair />}/>
                  <Route path="assetDisposal" element={<AssetDisposal />}/>
                  <Route path="transferAssets" element={<TransferAssetsWarehouse />}/>
                  <Route path="shopRepair/:requestCode" element={<ShopRepairDetails />} />
                  <Route path="assetDisposal/:requestCode" element={<AssetDisposalDetails />} />
                  <Route path="warehouseAudit" element={<WarehouseAudit />}/>
                  {/* <Route path="warehouseAssignments" element={<WarehouseAssignments />}/> */}
                </Route>

                <Route path="/request/:requestCode" element={<RequestDetails />} />
                <Route path="/pulloutRequest/:requestCode" element={<PulloutRequestDetails />} />
                <Route path="/serviceCallRequests/:requestCode" element={<ServiceRequestDetail />} />
                <Route path="/storeToStoreTransfers/:requestCode" element={<StoreToStoreTransfersDetails />} />
                <Route path="/transferAssets/:requestCode" element={<TransferAssetsWarehouseDetails />} />
                <Route path="/transferAssets/:requestCode/OtherCharges" element={<OtherChargesList />} />
                <Route path="/transferAssets/:requestCode/OtherCharges/:id" element={<ViewOtherCharges />} />
                <Route path="/jobOrders/:jorOrderIdunique" element={<JobOrderDetails />} />
                <Route path="settings" element={<GeneralSettings />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                
                {/* TO BE ADD 404 NOT FOUND */}
                <Route path="*" element={<NotFound />} />
              </Route>
              </Route>
              
            </Route>
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


export default App;
