import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Grid, Paper, StyledEngineProvider, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Chip, Breadcrumbs } from '@mui/material';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useAuth from '../../hooks/UseAuth';
import { format } from 'date-fns';
import { ServiceRequestProvider } from '../../context/ServiceRequestProvider';
import AssetDetails from './AssetDetails';
import EditServiceRequest from './EditServiceRequest';
import useServiceRequestContext from '../../hooks/UseServiceRequestContext';
import UnitForPullout from './UnitForPullout';
import Repaired from './Repaired';
import MoreOptionBtn from './MoreOptionBtn';

const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);


const ServiceRequestDetail = () => {
  const { requestCode } = useParams();
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [doneUpdate, setDoneUpdate] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [requestDetails, setRequestDetails] = useState({});
  const [assetCode, setAssetCode] = useState([]);
  const getAssets = async () => {
    try {
      setLoading(true);
      const options = {
          'method': 'POST',
          'url' : '/ServiceRequests/getServiceRequestsDetails.json',
          'data': {'request_code': requestCode}
      }
      const response = await axios(options);
      const data = response.data;
      setRequestDetails(data);
      setAssetCode(data.asset_code)
      setLoading(false);
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAssets();
  }, [doneUpdate]);

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  return (
    <ServiceRequestProvider>
    <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/serviceCallRequests"
          >
            Service Call Requests
          </Link>
          <Typography color="text.primary">Details</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container direction='column' spacing={1}>
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%', padding: '8px', display: 'flex', gap: '8px' }}>
                    <EditServiceRequest requestDetails={requestDetails} setDoneUpdate={setDoneUpdate}/>
                    {Number(auth.role) === 6 || Number(auth.role) === 0 ? (
                      <>
                        <UnitForPullout requestDetails={requestDetails} setDoneUpdate={setDoneUpdate}/>
                        <Repaired requestDetails={requestDetails} setDoneUpdate={setDoneUpdate}/>
                      </>
                    ): ''}
                    
                    {Number(auth.role) === 6 || Number(auth.role) === 2 || Number(auth.role) === 0 || Number(auth.role) === 3  ? (
                      <>

                        <MoreOptionBtn requestDetails={requestDetails} setDoneUpdate={setDoneUpdate}/>

                      </>
                    ) : ''}
                    
                  </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={1} style={{ height: '100%', padding: '8px' }}>
                  <Box mb={1}>
                    <Typography><b>Complaints:</b></Typography>
                    {requestDetails && requestDetails.complaints}
                  </Box>

                  <Box mb={1}>
                    <Typography><b>Tech Findings:</b></Typography>
                    {requestDetails && requestDetails.tech_findings}
                  </Box>
                  <Box mb={1}>
                    <Typography><b>Remarks:</b></Typography>
                    {requestDetails && requestDetails.remarks}
                  </Box>
                </Paper>
              </Grid>
              
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%' }}>
                  {assetCode.length > 0 &&
                    <AssetDetails asset_code={assetCode}/>
                  }
                  </Paper>
              </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={1} style={{ height: '100%' }}>
            <Typography p={2}>Info</Typography>
              <StyledTable sx={{ width: '100%' }} size="small">
                  <TableHead>
                  </TableHead>
                  <TableBody>
                  {Object.entries(requestDetails)
                    .filter(([key, _]) => key !== 'complaints' && key !== 'account_code' && key !== 'account_name' && key !== 'asset_code' && key !== 'asset_name' && key !== 'branch_code' && key !== 'request_code' && key !== 'username' && key !== 'remarks')
                    .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                      <TableCell style={key === 'status' ? { backgroundColor: value === '0' ? 'blue' : value === '1' ? 'orange' : 'inherit' } : {}}>
                        {key === 'status' ? (
                            value === 0 ? <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4'}} /> : 
                            value === 1 ? <Chip label='Repaired at site' sx={{ backgroundColor: '#3AD658' }} />: 
                            value === 2 ? <Chip label='Unit for Pullout' sx={{ backgroundColor: '#F1FA67' }} />: 
                            value === 3 ? <Chip label='Cancelled' sx={{ backgroundColor: '#CA863E', color: '#fff' }} />: 
                            value === 4 ? <Chip label='For Billing' sx={{ backgroundColor: '#2174AE', color: '#fff' }} />: 
                            value === 5 ? <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />: 
                            ''
                        ) : key === 'is_major_repair' ? (
                            value === 0 ? 'No' : 
                            value === 1 ? 'Yes': 
                            ''
                        ) : key === 'date_added' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'date_reported' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'completion_date' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'date_serviced' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'services' && value ? (
                          value.split(',').map((service, index) => {
                              return (
                              <div key={index}>{service}</div>
                            );
                            })
                        ) : (
                            value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </StyledTable>
          </Paper>
        </Grid>
      </Grid>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </ServiceRequestProvider>
  )
}

export default ServiceRequestDetail