import { useTheme } from '@emotion/react';
import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import { CircularProgress, Grid, Paper, StyledEngineProvider, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Button, Chip, Breadcrumbs, ImageList, ImageListItem, Tooltip } from '@mui/material';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import { CheckCircleOutline, Edit, HighlightOff } from '@mui/icons-material';
import ImageBeforeRepairSlide from './ImageBeforeRepairSlide';
import AssetDisplay from './AssetDisplay';
import useAuth from '../../hooks/UseAuth';
import RejectRepair from './RejectRepair';
import ApproveRepairRequest from './ApproveRepairRequest';
import { format } from 'date-fns';
import EditRepairRequest from './EditRepairRequest';
import CloseRequest from './CloseRequest';
import ImageAfterRepairSlide from './ImageAfterRepairSlide';
import ForScrapRequest from './ForScrapRequest';
import { ShopRepairProvider } from '../../context/ShopRepairProvider';
import UpdateRemarks from './UpdateRemarks';
import MoreOptionBtn from './MoreOptionBtn';

const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);
const ShopRepairDetails = () => {
  const { requestCode } = useParams();
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [onRejectOrApprove, setonRejectOrApprove] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [repairDetails, setRepairDetails] = useState({});
  const [assetCode, setAssetCode] = useState([]);
  const [hoveredService, setHoveredService] = useState(null);
  const [servicePrice, setServicePrice] = useState(null);
  const [services, setServices] = useState([]);

  const getAssets = async () => {
    try {
      setLoading(true);
      const options = {
          'method': 'POST',
          'url' : '/ShopRepairs/getShopRepairDetails.json',
          'data': {'request_code': requestCode}
      }
      const response = await axios(options);
      const data = response.data;
      setRepairDetails(data);
      setAssetCode(data.asset_code)
      setLoading(false);
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAssets();
  }, [onRejectOrApprove]);

  const getServicePrice = (service) => {
    const serviceObj = repairDetails.services_array.find(s => s.service_name === service);
    return serviceObj ? serviceObj.price : null;
  };

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const imageBeforeData = Object.entries(repairDetails)
    .filter(([key, _]) => key.startsWith('image_before_'))
    .map(([_, value]) => value);

  const imageAfterData = Object.entries(repairDetails)
    .filter(([key, _]) => key.startsWith('image_after_'))
    .map(([_, value]) => value);


  return (
    <ShopRepairProvider>
    <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/shopRepair"
          >
            Shop Repair
          </Link>
          <Typography color="text.primary">Details</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Grid container direction='column' spacing={1}>
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%', padding: '8px', display: 'flex', gap: 5 }}> 
                    {(Number(auth.role) === 6 || Number(auth.role) === 0)  && (
                      <>
                        <EditRepairRequest repairDetails={repairDetails} setonRejectOrApprove={setonRejectOrApprove} />
                        <CloseRequest repairDetails={repairDetails} setonRejectOrApprove={setonRejectOrApprove}/>
                        <ForScrapRequest repairDetails={repairDetails} setonRejectOrApprove={setonRejectOrApprove}/>
                      </>
                    )}
                    
                    {Number(auth.role) !== 6 && (
                      <>
                        <ApproveRepairRequest repairDetails={repairDetails} setonRejectOrApprove={setonRejectOrApprove}/>
                        <RejectRepair repairDetails={repairDetails} setonRejectOrApprove={setonRejectOrApprove}/>
                      </>
                    )}

                    {(Number(auth.role) === 2 || Number(auth.role) === 0)  && (
                      <>
                        <UpdateRemarks repairDetails={repairDetails} setonRejectOrApprove={setonRejectOrApprove}/>
                        <MoreOptionBtn repairDetails={repairDetails} setonRejectOrApprove={setonRejectOrApprove} />
                      </>
                    )}
                    
                  </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={1} style={{ height: '100%', padding: '8px' }}>
                  <ImageBeforeRepairSlide imageBeforeData={repairDetails && imageBeforeData}/>
                  <ImageAfterRepairSlide imageAfterData={repairDetails && imageAfterData}/>
                </Paper>
              </Grid>
              
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%' }}>
                  {assetCode.length > 0 &&
                    <AssetDisplay asset_code={assetCode}/>
                  }
                  </Paper>
              </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={1} style={{ height: '100%' }}>
            <Typography p={2}>Repair details</Typography>
              <StyledTable sx={{ width: '100%' }} size="small">
                  <TableHead>
                  </TableHead>
                  <TableBody>
                  {Object.entries(repairDetails)
                    .filter(([key, _]) => !key.startsWith('image_before_') && !key.startsWith('image_after_') && key !== 'images_before_repair' && key !== 'images_after_repair' && key !== 'services_array' &&
                    (Number(auth.role) === 2 || key !== 'remarks'))
                    .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                      <TableCell style={key === 'status' ? { backgroundColor: value === '0' ? 'blue' : value === '1' ? 'orange' : 'inherit' } : {}}>
                        {key === 'services' ? (
                          value.split(',').map((service, index) => {
                              const price = getServicePrice(service.trim());
                              return Number(auth.role) === 2 || Number(auth.role) === 3 ? (
                              <Tooltip
                                key={index}
                                title={price ? `Price: ${price}` : 'No price available'}
                                arrow
                              >
                                <div
                                  onMouseEnter={() => setHoveredService(service)}
                                  onMouseLeave={() => setHoveredService(null)}
                                  style={{ display: 'inline-block', margin: '0 5px', cursor: 'pointer', textDecoration: 'underline' }}
                                >
                                  {service}
                                </div>
                              </Tooltip>
                            ) : (
                              <div key={index}>{service}</div>
                            );
                            })
                        ) : key === 'status' ? (
                            value === 0 ? <Chip label='For Approval' sx={{ backgroundColor: '#35A7CA' }} /> : 
                            value === 1 ? <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4'}} />: 
                            value === 2 ? <Chip label='Sr form for completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />: 
                            value === 3 ? <Chip label='Asset for scrap' sx={{backgroundColor: '#DD1F00', color: '#fff' }} />: 
                            value === 4 ? <Chip label='Rejected' sx={{ backgroundColor: '#000001', color: '#fff' }} />: 
                            value === 5 ? <Chip label='Closed' color='success' />: 
                            value === 6 ? <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />: 
                            value === 7 ? <Chip label='For Billing'  sx={{ backgroundColor: '#2174AE', color: '#fff' }} />: 
                            value === 8 ? <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />: 
                            value === 9 ? <Chip label='Repair on-hold' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />: 
                            value === 10 ? <Chip label='Repaired at shop' sx={{ backgroundColor: '#3AD658' }} />: 
                            ''
                        ) : key === 'is_major_repair' ? (
                            value === 0 ? 'No' : 
                            value === 1 ? 'Yes': 
                            ''
                        ) : key === 'date_approved' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'date_rejected' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'signature' ? (
                            <img
                              src={value}
                              alt='after repair'
                              style={{ width: 200, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                            />
                        ) : (
                            value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </StyledTable>
          </Paper>
        </Grid>
      </Grid>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </ShopRepairProvider>
  )
}

export default ShopRepairDetails