import { Box, Button, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import AddAccounts from './AddAccounts';
import EditAccounts from './EditAccounts';
import DeleteAccounts from './DeleteAccounts';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import { useTheme } from '@emotion/react';
import Add from './Add';

const Accounts = () => {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [sortModel, setSortModel] = useState([]);
  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <EditAccounts accountsData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteAccounts accountsData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <Button 
            variant="outlined" 
            size="small" 
            sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
            onClick={() => { window.location.href = `/customers/${params.row.customer_no}`} }
          >
          View
          </Button>
        </Box>
      ),
    },
    { field: 'customer_no', headerName: 'JDE - Syspro',flex: 1, minWidth: 150, wrap: true },
    { field: 'store_name', headerName: 'Store Name', flex: 1, minWidth: 150, wrap: true},
    { field: 'business_address', headerName: 'Bus. address', flex: 1, minWidth: 150, wrap: true},
    { field: 'residential_address', headerName: 'Res. address', flex: 1, minWidth: 150, wrap: true},
    { field: 'distributor_account', headerName: 'Distributor/ Account',flex: 1, minWidth: 250, wrap: true },
    { field: 'contact', headerName: 'Contact',flex: 1, minWidth: 150, wrap: true },
    { field: 'owner_mngr_buyer_name', headerName: 'Owner / Manager / Buyer\'s name',flex: 1, minWidth: 150, wrap: true },
    // { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
    //   valueFormatter: (params) => {
    //     console.log(params);
        
    //     const date = new Date(params);
    //     return format(date, 'yyyy-MM-dd HH:mm:ss');
    //   }
    // },
    // { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 150,  
    //   valueFormatter: (params) => {
    //     const date = new Date(params);
    //     return format(date, 'yyyy-MM-dd HH:mm:ss');
    //   }
    // },
  ];


  useEffect(() => {
    const getAccounts = async (paginationModel, filterModel, sortModel) => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/ApiAccounts/getAccounts.json',
          'data': {
            page: paginationModel.page, 
            pageSize: paginationModel.pageSize,
            filters: filterModel.items,
            sort: sortModel,
          }
        }
        const response = await axios(options);
        const {data, count} = response.data;

        if (data) {
          setAccounts(data)
          setRowCount(count);
        }
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAccounts(paginationModel, filterModel, sortModel);
  }, [onEditOrDelete, paginationModel, filterModel, sortModel]);

  
  const handleFilterChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', gap: 2 }}>
      {/* add users button */}
      <Add setOnEditOrDelete={setOnEditOrDelete}/>
      <AddAccounts setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        rows={accounts}
        columns={columns}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length} />
        }}
        loading={loading}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={[10, 25, 50, 100]}
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        autoHeight
        keepNonExistentRowsSelected
        getRowId={(row) => row.customer_no}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: 1,
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '10px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '10px',
            },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default Accounts