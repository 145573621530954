import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';

const AddTransferRequest = ({assetCode, setOnEdit}) => {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [stores, setStores] = useState([]);
  const [openStore, setOpenStore] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [destinatioStores, setDestinatioStores] = useState([]);
  const [openDestinatioStores, setOpenDestinatioStores] = useState(false);
  const [destinatioStoresLoading, setDestinatioStoresLoading] = useState(false);
  const [deploymentTypes, setDeploymentTypes] = useState([]);
  const [openDeploymenTypes, setOpenDeploymenTypes] = useState(false);
  const [depTypeLoading, setDepTypeLoading] = useState(false);
  const [transferRequest, setTransferRequest] = useState({
    deploymenTypes: [],
    deployment_type: '',
    assetSelected: [],
    asset_code: '',
    asset_name: '',
    account_code: '', 
    account_name: '', 
    branch_code: '',
    branch_name: '',
    destination_account_code: '', 
    destination_account_name:  '', 
    destination_branch_code:  '',
    destination_branch_name:  '',
    warehouseSelected: [],
    warehouse_unique_code: '',
    warehouse_name: '',
  });

  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation checks
    if (!validateRequiredFields()) return;
    

    try {
      const options = {
          method: 'POST',
            url: '/StoreToStoreTransfers/addRequest.json',
            data: objectToFormData(transferRequest),
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setOnEdit((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setTransferRequest({
      deploymenTypes: [],
      deployment_type: '',
      assetSelected: [],
      asset_code: '',
      asset_name: '',
      account_code: '', 
      account_name: '', 
      branch_code: '',
      branch_name: '',
      destination_account_code: '', 
      destination_account_name:  '', 
      destination_branch_code:  '',
      destination_branch_name:  '', 
      warehouseSelected: [],
      warehouse_unique_code: '',
      warehouse_name: '',
    })
  }

  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, assetSelected: selectValue, asset_code: selectValue.asset_code, asset_name: selectValue.asset_name})
      )
    } 

    setTransferRequest((prevAccount) => ({ ...prevAccount, assetSelected: [], asset_code: '', asset_name: ''}))
  }
  const hanldeDeploymentTypeChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, deploymenTypes: selectValue, deployment_type: selectValue.name})
      )
    } 

    setTransferRequest((prevAccount) => ({ ...prevAccount, deploymenTypes: [], deployment_type: ''}))
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, warehouseSelected: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    } 

    setTransferRequest((prevAccount) => ({ ...prevAccount, warehouseSelected: [], warehouse: []}))
  }

  const hanldeStoreChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ 
          ...prevAccount, 
          stores: selectValue, 
          account_code: selectValue.account_code, 
          account_name: selectValue.account_name, 
          branch_code: selectValue.branch_code,
          branch_name: selectValue.branch_name,
        })
      )
    } 

    setTransferRequest((prevAccount) => ({ 
      ...prevAccount, 
      stores: [], 
      account_code: '', 
      account_name: '', 
      branch_code: '',
      branch_name: '',
    }))
  }
  const hanldeDestinationStoreChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ 
          ...prevAccount, 
          stores: selectValue, 
          destination_account_code: selectValue.account_code, 
          destination_account_name: selectValue.account_name, 
          destination_branch_code: selectValue.branch_code,
          destination_branch_name: selectValue.branch_name,
        })
      )
    } 

    setTransferRequest((prevAccount) => ({ 
      ...prevAccount, 
      stores: [], 
      destination_account_code: '', 
      destination_account_name:  '', 
      destination_branch_code:  '',
      destination_branch_name:  '', 
    }))
  }

  const getDeploymentTypes = async (ignore) => {
    try {
      setDepTypeLoading(true)
        const options = {
            method: 'POST',
            url: '/DeploymentTypes/getDeploymentTypesForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setDeploymentTypes(response.data);
          setDepTypeLoading(false)
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAllDeployedAssets.json',
            data: {asset_code : assetCode},
        }
        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getStore = async (ignore) => {
    setStoreLoading(true)
    try {
        const options = {
            method: 'POST',
            url: '/StoreAssets/getStoreforServiceRequest.json',
            data: {asset_code: transferRequest.asset_code}
        }

        const response = await axios(options);
        if (!ignore) {
          setStores(response.data);
          setStoreLoading(false)
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getDestinatioStore = async (ignore) => {
    setDestinatioStoresLoading(true)
    try {
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getAllStoresForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setDestinatioStores(response.data);
          setDestinatioStoresLoading(false)
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openAssets) {
      setAssets([]);
    }

    if (!openAssets) return undefined
    getAssets(ignore)

    return () => {
      ignore = true
    };
  }, [openAssets]);

  useEffect(() => {
    let ignore = false;
    if (!openStore) {
      setStores([]);
    }

    if (!openStore) return undefined
    getStore(ignore)

    return () => {
      ignore = true
    };
  }, [openStore]);

  useEffect(() => {
    let ignore = false;
    if (!openDestinatioStores) {
      setDestinatioStores([]);
    }

    if (!openDestinatioStores) return undefined
    getDestinatioStore(ignore)

    return () => {
      ignore = true
    };
  }, [openDestinatioStores]);

  useEffect(() => {
    let ignore = false;
    if (!openDeploymenTypes) {
      setDeploymentTypes([]);
    }

    if (!openDeploymenTypes) return undefined
    getDeploymentTypes(ignore)

    return () => {
      ignore = true
    };
  }, [openDeploymenTypes]);


  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  const validateRequiredFields = () => {
    const requiredFields = ['asset_code', 'account_code', 'destination_account_code', 'deployment_type', 'warehouse_unique_code'];

    for (const field of requiredFields) {
      if (!transferRequest[field]) {
        setOpenMessageDialog(true);
        setMessage('All Fields are required');
        return false;
      }
    }

    return true;
  };

  
  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>Create Request</Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >
      <DialogTitle>Add New Transfer request</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openAssets}
                onOpen={() => {setOpenAssets(true);}}
                onClose={() => {setOpenAssets(false);}}
                loading={loading}
                onChange={hanldeAssetsChange}
                options={assets}
                value={assets.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select asset*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openStore}
                onOpen={() => {setOpenStore(true);}}
                onClose={() => {setOpenStore(false);}}
                loading={storeLoading}
                onChange={hanldeStoreChange}
                options={stores}
                value={stores.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Origin Store*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {storeLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openDestinatioStores}
                onOpen={() => {setOpenDestinatioStores(true);}}
                onClose={() => {setOpenDestinatioStores(false);}}
                loading={destinatioStoresLoading}
                onChange={hanldeDestinationStoreChange}
                options={destinatioStores}
                value={destinatioStores.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Destination Store*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {destinatioStoresLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openDeploymenTypes}
                onOpen={() => {setOpenDeploymenTypes(true);}}
                onClose={() => {setOpenDeploymenTypes(false);}}
                loading={depTypeLoading}
                onChange={hanldeDeploymentTypeChange}
                options={deploymentTypes}
                value={deploymentTypes.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Deployment type*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {depTypeLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openWarehouse}
                onOpen={() => {setOpenWarehouse(true);}}
                onClose={() => {setOpenWarehouse(false);}}
                loading={warehouseLoading}
                onChange={hanldeWarehouseChange}
                options={warehouses}
                value={warehouses.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Warehouse Branch*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default AddTransferRequest