import { Button, Dialog, DialogActions, DialogContent, DialogTitle, StyledEngineProvider, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ProcessDialog from './ProcessDialog';
import MessageDialog from './MessageDialog';
import { PictureAsPdf, Print } from '@mui/icons-material';
import { PDFDocument , rgb , StandardFonts  } from 'pdf-lib';
import logo from '../../images/logo.png'

function FasrPrint({ requestDetails }) {
  
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState('');
  const [content, setContent] = useState('');
  const [noFasr, setNoFasr] = useState(false);
  const [pdfContent, setPdfContent] = useState(null); // To store the filled PDF


  useEffect(() => {
    
    const fillPdfForm = async () => {
      try {

        setNoFasr(false);
    
        const { pdfDoc, page } = await createPdfDocument();
        const { boldFont, regularFont } = await loadFonts(pdfDoc);
    
        // Draw the logo and address
        await drawLogoAndAddress(page, logo, pdfDoc, boldFont, regularFont);
   
          const margin = 20; // Margin from the edges
          const rectangleY = page.getHeight() - 190; // Position the rectangle below the logo
          const rectangleHeight = 34; // Height of the rectangle
          const rectangleWidth = page.getWidth() - margin * 2; // Full width of the page minus margins

          // Draw rectangle box with margins
          page.drawRectangle({
            x: margin,
            y: rectangleY,
            width: rectangleWidth,
            height: rectangleHeight,
            color: rgb(0.9, 0.9, 0.9), 
            borderColor: rgb(0, 0, 0), 
            borderWidth: 1,
          });

  
          const titleText = 'TRADE ASSET DISPOSAL FORM';
          const titleFontSize = 12;
          const titleWidth = boldFont.widthOfTextAtSize(titleText, titleFontSize);
          const titleX = margin + (rectangleWidth - titleWidth) / 2; 
          const titleY = rectangleY + (rectangleHeight / 2) - (titleFontSize / 2); 
          page.drawText(titleText, {
            x: titleX,
            y: titleY,
            size: titleFontSize,
            font: boldFont,
            color: rgb(0, 0, 0), 
          });

        drawLabeledText(page, 'Service Notification No:', requestDetails.request_code, 29, 630, 12, boldFont, regularFont);
        drawLabeledText(page, 'Warehouse Branch:', requestDetails.warehouse_name, 29, 581, 12, boldFont, regularFont);
        drawLabeledText(page, 'Disposal details:', '', 29, 564, 12, boldFont, regularFont);
        if (requestDetails.date_added) {
          drawLabeledText(page, 'Request Date:', formatDate(requestDetails.date_added), 29, 595, 12, boldFont, regularFont);
        }
        if (requestDetails.date_disposed) {
          drawLabeledText(page, 'Date Disposal:', formatDate(requestDetails.date_disposed), 360, 595, 12, boldFont, regularFont);
        }
    
        const assetDetails = requestDetails.asset_details || [];

        const tableData = [
          ['Equipment No', 'Asset JDE No', 'Asset Model', 'Asset Status', 'Netbook Value', 'Serial No'],
          ...assetDetails.map(asset => [

            '', 
            '',
            asset.model || '',
            asset.status_name || '',
            asset.net_book_value || '',
            asset.serial_no || ''

          ])
        ];


        const rows = tableData.length;

        const footerParams = ['Remarks:', requestDetails.remarks , 'Scrap Proceeds:', '', boldFont, regularFont];
        const columnWidths = [80, 80, 110, 100, 80, 70];
        const rowHeight = 20;
        const numRows = rows;
    
        await drawTable(page, 30, 500, columnWidths, rowHeight, numRows, tableData, pdfDoc, drawFooter, footerParams);

        const pdfBytes = await pdfDoc.save();
        const filledPdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const filledPdfUrl = URL.createObjectURL(filledPdfBlob);
        setPdfContent(filledPdfUrl);
        
      } catch (error) {
        console.error("Error filling PDF form:", error);
        setNoFasr(true);
      }
    };


      fillPdfForm();

  }, [requestDetails]);

  const handleOpen = () => {
    setOpen(true);
    console.log(pdfContent);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrintPDF = async () => {
    if (pdfContent) {


        // Directly download the PDF
        const link = document.createElement('a');
        link.href = pdfContent;
        link.download = 'fasr.pdf';
        link.click();

      
    }
  };

  const handlePrintWord = () => {
    if (pdfContent) {
      const link = document.createElement('a');
      link.href = pdfContent;
      link.download = 'fasr.docx';
      link.click();
    }
  };

  function formatDate(dateString) {

      const date = new Date(dateString);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      return `${year}-${month}-${day}`;

  }
  const drawFooter = (page, remarksLabel, remarksValue, scrapProceedsLabel, scrapProceedsValue, boldFont, regularFont) => {

    const marginLeft = 34; // Margin from the left side
    const marginRight = 34; // Margin from the right side
    const marginBottom = 50; // Margin from the bottom
    const pageWidth = page.getWidth(); // Get the width of the page
    const footerY = marginBottom + 80; // Adjust this to control vertical positioning of the footer content (above the margin)

    // Calculate box width based on page width and margins
    const availableWidth = pageWidth - marginLeft - marginRight;
    const boxWidth = (availableWidth) / 2; // Two boxes with space between them
    const boxHeight = 100; // Height of the boxes
    const boxY = footerY - 100 - 23; // Vertical position of the boxes

    // Draw the "Remarks" label and value
    page.drawText(remarksLabel, {
        x: marginLeft,
        y: footerY,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    page.drawText(remarksValue, {
        x: marginLeft + boldFont.widthOfTextAtSize(remarksLabel, 10) + 5,
        y: footerY,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    // Draw the "Scrap Proceeds" label and value
    page.drawText(scrapProceedsLabel, {
        x: marginLeft,
        y: footerY - 20,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    page.drawText(scrapProceedsValue, {
        x: marginLeft + boldFont.widthOfTextAtSize(scrapProceedsLabel, 10) + 5,
        y: footerY - 20,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    // Draw left box for "Requested by:"
    const leftBoxX = marginLeft; // X position for the left box (with left margin)
    page.drawRectangle({
        x: leftBoxX,
        y: boxY,
        width: boxWidth,
        height: boxHeight,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
    });

    // Draw "Requested by:" text centered in the top portion of the left box
    const requestedByLabel = 'Requested by:';
    const textWidth = boldFont.widthOfTextAtSize(requestedByLabel, 12);
    const textX = leftBoxX + (boxWidth - textWidth) / 2; // Center the text
    page.drawText(requestedByLabel, {
        x: textX,
        y: boxY + boxHeight - 20, // Position at top center of the box
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    // Add a line for the signature in the left box
    const signatureY = boxY + boxHeight - 50;
    const signatureLineWidth = 120;
    const signatureX = leftBoxX + (boxWidth - signatureLineWidth) / 2;
    page.drawText('________________________', {
        x: signatureX,
        y: signatureY,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('Signature over printed name', {
        x: signatureX,
        y: signatureY - 15,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('(Centigen Admin)', {
        x: signatureX + 20,
        y: signatureY - 30,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    // Draw right box for "Approved by:"
    const rightBoxX = leftBoxX + boxWidth; // X position for the right box
    page.drawRectangle({
        x: rightBoxX,
        y: boxY,
        width: boxWidth,
        height: boxHeight,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
    });

    // Draw "Approved by:" text centered in the top portion of the right box
    const approvedByLabel = 'Approved by:';
    const textWidthApproved = boldFont.widthOfTextAtSize(approvedByLabel, 12);
    const textXApproved = rightBoxX + (boxWidth - textWidthApproved) / 2; // Center the text
    page.drawText(approvedByLabel, {
        x: textXApproved,
        y: boxY + boxHeight - 20, // Position at top center of the box
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    // Draw the same signature line and name as the left box for the right box
    page.drawText('________________________', {
        x: signatureX + boxWidth, // Move the signature X position to the right box
        y: signatureY,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('Signature over printed name', {
        x: signatureX + boxWidth, // Move the text X position to the right box
        y: signatureY - 15,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('(Froneri Approver)', {
        x: signatureX + boxWidth + 20, // Move the text X position to the right box
        y: signatureY - 30,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    // Draw Note
    const noteText = '* This is to confirm that all asset\'s body or frame has been destroyed.';
    page.drawText(noteText, {
        x: leftBoxX + 14,
        y: boxY + 10,
        size: 8,
        font: regularFont,
        color: rgb(0, 0, 0),
    });
};

  const createPdfDocument = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([595, 842]); // A4 dimensions
    return { pdfDoc, page };
  };
  
  const loadFonts = async (pdfDoc) => {
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    return { boldFont, regularFont };
  };
  
  // Helper function to draw text with a bold label and regular text
  const drawLabeledText = (page, label, value, x, y, fontSize, boldFont, regularFont) => {

    page.drawText(label, {
      x, y, size: fontSize, font: boldFont, color: rgb(0, 0, 0),
    });
    const textX = x + boldFont.widthOfTextAtSize(label, fontSize) + 5;
    page.drawText(value, {
      x: textX, y, size: fontSize, font: regularFont, color: rgb(0, 0, 0),
    });

  };
  
  // Helper function to draw the logo and address
  const drawLogoAndAddress = async (page, logo, pdfDoc, boldFont, regularFont) => {
    const imageBytes = await fetch(logo).then((res) => res.arrayBuffer());
    const embeddedImage = await pdfDoc.embedPng(imageBytes);
    const { width, height } = embeddedImage.scale(0.5); // Scale down the image
  
    const logoX = 50;
    const logoY = page.getHeight() - height - 50;
    
    page.drawImage(embeddedImage, {
      x: logoX, y: logoY, width, height,
    });
  
    const textX = logoX + width + 20;
    const textY = logoY + height - 10;
    
    page.drawText('FRONERI PHILIPPINES, INC.\nNational Highway,\nBarangay Tibag,\nPullilan, Philippines', {
      x: textX, y: textY, size: 10, font: boldFont, lineHeight: 12, color: rgb(0, 0, 0),
    });
  
    const hotlineY = textY - 48;
    page.drawText('Trade, Asset hotline:', {
      x: textX, y: hotlineY, size: 10, font: boldFont, color: rgb(0, 0, 0),
    });
  
    page.drawText('0922 882 6014 / 0920 928 7670\n/ 0917 516 6153', {
      x: textX + 110, y: hotlineY, size: 10, font: regularFont, color: rgb(0, 0, 0),
    });
  };
  
  async function drawTable(page, startX, startY, columnWidths, rowHeight, numRows, data, pdfDoc, drawFooterFn, footerParams) {

    const numberOfColumns = columnWidths.length;
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    
    let currentPage = page;
    let currentY = startY;
    const pageHeight = page.getHeight();
    const footerHeight = 120; // Height reserved for the footer

    for (let i = 0; i < numRows; i++) {
        // Check if the next row will fit on the current page
        if (currentY - rowHeight < footerHeight) {
            // Draw the bottom border of the last row on the current page
            currentPage.drawLine({
                start: { x: startX, y: currentY },
                end: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: currentY },
                thickness: 1,
                color: rgb(0, 0, 0),
            });

            // Create a new page
            currentPage = pdfDoc.addPage();
            currentY = pageHeight - 20; // Reset Y position on new page

            // Draw the top border of the first row on the new page
            currentY -= rowHeight; // Move Y position down for the first row on the new page
        }

        // Draw the top border of the row
        currentPage.drawLine({
            start: { x: startX, y: currentY },
            end: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: currentY },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        // Move Y position down for the next row
        currentY -= rowHeight;

        // Draw vertical lines for columns
        let currentX = startX;
        for (let j = 0; j < numberOfColumns; j++) {
            // Draw vertical line for each column's left border
            currentPage.drawLine({
                start: { x: currentX, y: currentY + rowHeight }, // Adjusted Y position
                end: { x: currentX, y: currentY },
                thickness: 1,
                color: rgb(0, 0, 0),
            });

            // Add the text if data exists
            if (data[i] && data[i][j]) {
                const font = i === 0 ? boldFont : regularFont; // Use bold font for the first row (header)
                currentPage.drawText(data[i][j], {
                    x: currentX + 5,
                    y: currentY + 5,
                    size: 10,
                    font: font,
                    color: rgb(0, 0, 0),
                });
            }

            // Move to the right for the next column
            currentX += columnWidths[j];

            // Draw the right border of the last column (column 6)
            if (j === numberOfColumns - 1) {
                currentPage.drawLine({
                    start: { x: currentX, y: currentY + rowHeight }, // Adjusted Y position
                    end: { x: currentX, y: currentY },
                    thickness: 1,
                    color: rgb(0, 0, 0),
                });
            }
        }
    }

    // Draw the bottom border of the last row on the last page
    currentPage.drawLine({
        start: { x: startX, y: currentY },
        end: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: currentY },
        thickness: 1,
        color: rgb(0, 0, 0),
    });

    // Draw the last vertical line to close the right border of the table
    currentPage.drawLine({
        start: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: startY },
        end: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: currentY },
        thickness: 1,
        color: rgb(0, 0, 0),
    });

    // Draw the footer for the last page
    drawFooterFn(currentPage, ...footerParams); 

  }

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Print FASR</Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>Print FASR</DialogTitle>
        <DialogContent>
          {noFasr ? (
            <Typography variant="h6">No FASR available</Typography>
          ) :  
            <iframe
              src={pdfContent}
              title="PDF"
              width="100%"
              height="500px"
            />
          }
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handlePrintPDF} 
            color="primary" 
            startIcon={<PictureAsPdf />}
            disabled={noFasr}
          >
            Print as PDF
          </Button>
          <Button 
            onClick={handlePrintWord} 
            color="secondary" 
            startIcon={<Print />}
            disabled={noFasr}
          >
            Print as Word
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={false} ContentText="Loading Please Wait..." />
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={false}
          handleClose={() => {}}
          message=""
        />
      </StyledEngineProvider>
    </div>
  );
}

export default FasrPrint;
