import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, Typography } from '@mui/material';
import React, {useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import useCommentsContext from '../../hooks/UseCommentsContext';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function AddComments({customerNo, setOnEditOrDelete}) {
  const {setDoneUpdate} = useCommentsContext();
  const {auth} = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [comment, setComment] = useState({
    comments: '',
    customer_no: customerNo,
  });


  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!comment.comments) {
        setOpenMessageDialog(true);
        setMessage('Comment is required')
        return
    }

    if (!isValidSpecialCharacter(comment.comments) && comment.comments !== '') {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Comments.')
        return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AccountsComments/addComments.json',
              data: objectToFormData(comment),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);
        if (is_success) {
          resetForm();
          setDoneUpdate((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(message)
          
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setComment({
      comment: '',
      customer_no: customerNo,
    });
  };

  return (
    <div>
    <Button disabled = {Number(auth.role) === 6 ? true : false} variant="contained" size="small" onClick={handleOpen}>Add</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add Comment</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
          <Grid item xs={12}>
              <Typography>Comment:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Input Comments" 
                  onChange={(e) => setComment((prevAccount) => ({ ...prevAccount, comments: e.target.value }))}
                />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddComments